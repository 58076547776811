export const clubs = [
  {
    name: "onlyCEO Klub",
    color: "#1D595A",
    textColor: "white",
    text: "OC",
    tooltip: "onlyCEO Klub",
  },
  {
    name: "Női Vezetői Klub",
    color: "#BB2E84D4",
    textColor: "white",
    text: "NV",
    tooltip: "Női Vezetői Klub",
  },
  {
    name: "Ipari Klub",
    color: "#E1A02AD6",
    textColor: "white",
    text: "IK",
    tooltip: "Ipari Klub",
  },
];
