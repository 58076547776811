import './App.css';
import { Login } from './pages/Login';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { EventDetail } from './pages/EventDetail';
import { EventRegistrations } from './pages/EventRegistrations';
import { Home } from './pages/Home';
import { Events } from './pages/Events';
import { Archives } from './pages/Archives';
import { OnSite } from './pages/OnSite';
import { NewRegistrations } from './pages/NewRegistration';
import { SeatOrder } from './pages/SeatOrder/SeatOrder';
import { Waitings } from './pages/Waitings';
import { NotResponds } from './pages/NotResponds';
import { Applies } from './pages/Applies';
import { Interesteds } from './pages/Interesteds';
import { MobileAppPage } from './pages/MobileAppPage';
import { SendNotification } from './pages/SendNotification';
import { Messages } from './pages/Messages';

const router = createBrowserRouter([
  { path: '/', element: <Login />},
  // { path: '/events', element: <Events />},
  { path: '/home', element: <Home />, children: [
    {path: 'events', element: <Events />},
    {path: 'archives', element: <Archives />},
    {path: 'detail', element: <EventDetail />},
    {path: 'registrations', element: <EventRegistrations />},
    {path: 'onsite', element: <OnSite />},
    {path: 'interesteds', element: <Interesteds />},
    {path: 'waiting', element: <Waitings />},
    {path: 'applies', element: <Applies />},
    {path: 'notresponded', element: <NotResponds />},
    {path: 'newregistration', element: <NewRegistrations />},
    {path: 'seatorders', element: <SeatOrder />},
    {path: 'sendnotification', element: <SendNotification />},
    {path: 'messages', element: <Messages />},
  ]},
  { path: '/app', element: <MobileAppPage /> },
])

function App() {
  return <RouterProvider router={router} />
}

export default App;
