import React, { useEffect, useLayoutEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { Consts } from "../Consts";

import { EventModel } from "../model/Event";

export function Home() {
  const navigate = useNavigate()
  const [event, setEvent] = useState(null)

  useLayoutEffect(() => {
  }, [])
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      console.log("No Token!")
      window.location.href = ('/')
    }
    console.log(window.location.href)

    const pagesNotRequireEvent = ['/home/events', '/home/archives', '/home/messages']
    if (!localStorage.getItem('event') && !pagesNotRequireEvent.find(p => window.location.href.endsWith(p))) {
      navigate('events')
    }
  })

  useEffect(() => {
    console.log("getEvent")
    if(!localStorage.getItem('token') || !localStorage.getItem('event')) return
    fetch(Consts.baseUrl + "api/events/"+localStorage.getItem('event'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then(res => res.json())
    .then(res => {console.log(res); return res})
    .then(res => setEvent(new EventModel(res)))
  }, [])

  function logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('event')
    navigate('/')
  }

  return (
    <div style={{height: '100vh'}}>
      <div className="" style={{height: '100%'}}>
        <div className="nav col-2 flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{height: '100%', backgroundColor: '#334A73', overflowY: 'auto', flexWrap: "nowrap", paddingBottom: 16}}>
          <button onClick={()=>{localStorage.removeItem('event'); navigate('events')}} className={"nav-link"+(window.location.href.endsWith('/home/events')?" active" : "")} id="v-pills-events-tab" data-bs-toggle="pill" data-bs-target="#v-pills-events" type="button" role="tab" aria-controls="v-pills-events" aria-selected={!!localStorage.getItem('event')} style={{color: 'white'}}>
            Események
          </button>
          {
            localStorage.getItem('event') ?
              <React.Fragment>
                <hr className="nav-link-divider" />
                <div className="nav-link-event-title">{event?.title ?? '-'}</div>
                <button onClick={()=>navigate('detail')} className={"nav-link"+(window.location.href.endsWith('/home/detail')?" active" : "")} id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="false" style={{color: 'white'}}>Esemény adatlapja</button>
                <button onClick={()=>navigate('interesteds')} className={"nav-link"+(window.location.href.endsWith('/home/interesteds')?" active" : "")} id="v-pills-registrations-tab" data-bs-toggle="pill" data-bs-target="#v-pills-registrations" type="button" role="tab" aria-controls="v-pills-registrations" aria-selected="false" style={{color: 'white'}}>Érdeklődések</button>
                <button onClick={()=>navigate('waiting')} className={"nav-link"+(window.location.href.endsWith('/home/waiting')?" active" : "")} id="v-pills-registrations-tab" data-bs-toggle="pill" data-bs-target="#v-pills-registrations" type="button" role="tab" aria-controls="v-pills-registrations" aria-selected="false" style={{color: 'white'}}>Várólista</button>
                <button onClick={()=>navigate('applies')} className={"nav-link"+(window.location.href.endsWith('/home/applies')?" active" : "")} id="v-pills-registrations-tab" data-bs-toggle="pill" data-bs-target="#v-pills-registrations" type="button" role="tab" aria-controls="v-pills-registrations" aria-selected="false" style={{color: 'white'}}>Jelentkezések</button>
                <button onClick={()=>navigate('notresponded')} className={"nav-link"+(window.location.href.endsWith('/home/notresponded')?" active" : "")} id="v-pills-registrations-tab" data-bs-toggle="pill" data-bs-target="#v-pills-registrations" type="button" role="tab" aria-controls="v-pills-registrations" aria-selected="false" style={{color: 'white'}}>Vissza nem jelzettek</button>
                <button onClick={()=>navigate('registrations')} className={"nav-link"+(window.location.href.endsWith('/home/registrations')?" active" : "")} id="v-pills-registrations-tab" data-bs-toggle="pill" data-bs-target="#v-pills-registrations" type="button" role="tab" aria-controls="v-pills-registrations" aria-selected="false" style={{color: 'white'}}>Regisztráltak</button>
                <button onClick={()=>navigate('onsite')} className={"nav-link"+(window.location.href.endsWith('/home/onsite')?" active" : "")} id="v-pills-onsite-tab" data-bs-toggle="pill" data-bs-target="#v-pills-onsite" type="button" role="tab" aria-controls="v-pills-onsite" aria-selected="false" style={{color: 'white'}}>Helyszínen megjelentek</button>
                <button onClick={()=>navigate('newregistration')} className={"nav-link"+(window.location.href.endsWith('/home/newregistration')?" active" : "")} id="v-pills-new-tab" data-bs-toggle="pill" data-bs-target="#v-pills-new" type="button" role="tab" aria-controls="v-pills-new" aria-selected="false" style={{color: 'white'}}>Új résztvevő hozzáadása</button>
                <button onClick={()=>navigate('seatorders')} className={"nav-link"+(window.location.href.endsWith('/home/seatorders')?" active" : "")} id="v-pills-new-tab" data-bs-toggle="pill" data-bs-target="#v-pills-new" type="button" role="tab" aria-controls="v-pills-new" aria-selected="false" style={{color: 'white'}}>Résztvevők ültetése</button>
                <button onClick={()=>navigate('sendnotification')} className={"nav-link"+(window.location.href.endsWith('/home/sendnotification')?" active" : "")} id="v-pills-new-tab" data-bs-toggle="pill" data-bs-target="#v-pills-new" type="button" role="tab" aria-controls="v-pills-new" aria-selected="false" style={{color: 'white'}}>Értesítés küldése</button>
              </React.Fragment> :
              <button onClick={()=>navigate('archives')} className={"nav-link"+(window.location.href.endsWith('/home/archives')?" active" : "")} id="v-pills-events-tab" data-bs-toggle="pill" data-bs-target="#v-pills-events" type="button" role="tab" aria-controls="v-pills-events" aria-selected="false" style={{color: 'white'}}>
                Archive
              </button>
          }
          <hr className="nav-link-divider" />
          <button onClick={()=>navigate('messages')} className={"nav-link"+(window.location.href.endsWith('/home/messages')?" active" : "")} id="v-pills-new-tab" data-bs-toggle="pill" data-bs-target="#v-pills-new" type="button" role="tab" aria-controls="v-pills-new" aria-selected="false" style={{color: 'white'}}>Üzenetek</button>
          <button className="nav-link" type="button" role="tab" onClick={()=>logout()} style={{color: 'white'}}>Kijelentkezés</button>
        </div>
        <div className="tab-content" id="v-pills-tabContent" style={{height: '100%'}}>
          <Outlet context={{event, setEvent}} />
        </div>
      </div>
    </div>
  )
}
