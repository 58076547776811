import React from "react";
import styles from "./styles.module.css";
import { TableCard } from "./TableCard";

export const TableSeat = (props) => {
    const getParticipantDataForTableSeat = (user) => {
        return props.allParticipants.find(p => p.id === user);
    };

    return (
        <div
            className={`${styles.tableSeat} ${
                props.isSelected && styles.isCardSelected
            } `}
        >
            {!props.tableSeat.user ? (
                <div
                    className={`${styles.tableCard} ${
                        props.selectedParticipant && styles.participantIsSelected
                    } ${styles.emptyTableSeat}`}
                    onClick={() => {
                        props.handleSelectedParticipant(props.tableSeat);
                    }}
                >
                    <div className={styles.seatIdDiv}>{props.index}</div>
                    <div style={{ textAlign: "center" }}>Üres szék</div>
                </div>
            ) : (
                <TableCard
                    key={props.index}
                    index={props.index}
                    card={getParticipantDataForTableSeat(props.tableSeat.user)}
                    isSelected={props.tableSeat.user === props.selectedParticipant?.id}
                    handleParticipantSelection={props.handleParticipantSelection}
                    isVisible
                    onRemove={() => {
                        props.removeFromTableSelectedParticipant(props.tableSeat);
                    }}
                    conflictingTags={props.conflictingTags}
                />
            )}
        </div>
    );
};
