import React, { useEffect } from "react";
import { unstable_useBlocker } from "react-router-dom";

export function usePrompt(when = true, setIsNotSavedDialogueOpen) {
    const blocker = unstable_useBlocker(when);
  
    useEffect(() => {
      // message in latest Chrome will be ignored anyway - it uses its own
      if (when) window.onbeforeunload = () => "Are you sure?";
  
      return () => {
        window.onbeforeunload = null;
      };
    }, [when]);
  
    useEffect(() => {
      if (blocker.state === "blocked") {
        setIsNotSavedDialogueOpen(true);
      }
    }, [blocker]);
  }