export class Consts {
    static MONTHS = [
        'január',
        'február',
        'március',
        'április',
        'május',
        'június',
        'július',
        'augusztus',
        'szeptember',
        'október',
        'november',
        'december'
    ];
    static DAYS = [
        'vasárnap',
        'hétfő',
        'kedd',
        'szerda',
        'csütörtök',
        'péntek',
        'szombat',
    ];
    static allEventTitle = 'Összes rendezvény';
    static stackPageNames = {
        splash: 'Splash',
        login: 'Login',
        home: 'Home',
        event: 'Event',
        events: 'Events',
        camera: 'Camera',
        search: 'Search',
    };
    static defaultErrorTitle = 'Hiba!';
    static defaultErrorMessage = 'A művelet közben váratlan hiba lépett fel.';

    // SERVER
    static baseSiteUrl =
        process.env.REACT_APP_ENV === "production" ?
            "https://mvuk.prod.greendev.hu/" :
            process.env.REACT_APP_ENV === "sandbox" ?
                "https://mvuk-sb.prod.greendev.hu/" :
                "https://mvuk.staging2.greendev.hu/";

    // SERVER
    static baseUrl =
        process.env.REACT_APP_ENV === "production" ?
            "https://mvuk.prod.greendev.hu/api/" :
            process.env.REACT_APP_ENV === "sandbox" ?
                "https://mvuk-sb.prod.greendev.hu/api/" :
                "https://mvuk.staging2.greendev.hu/api/";

    // LOCAL
    // static baseUrl = "http://localhost:1337/"

    // STAGING
    // static baseUrl = "https://mvuk.staging2.greendev.hu/api/"

    // SANDBOX
    // static baseUrl = "https://mvuk-sb.prod.greendev.hu/api/"
}
