import React, { useEffect, useState } from "react";
import { TableCard } from "./TableCard";
import styles from "./styles.module.css";

export const ParticipantsPoolTable = (props) => {
  return (
    <div className={`${styles.participantsContainer}`}>
      <ul className={styles.ul}>
        {props.participants.length > 0 ? (
          props.participants.map((participant, index) => (
            <li
              key={index}
              onClick={(e) => {
                props.handleParticipantSelection(participant);
              }}
            >
              <TableCard
                key={index}
                card={participant}
                isSelected={participant === props.selectedParticipant}
              />
            </li>
          ))
        ) : (
          <span>Nincs több megjeleníthető résztvevő</span>
        )}
      </ul>
    </div>
  );
};
