import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { Consts } from "../Consts";
import { EventModel } from "../model/Event";
import { EventsModel } from "../model/Events";

export function Events() {
  const { setEvent } = useOutletContext();
  const navigate = useNavigate();
  const [events, setEvents] = useState();

  const handleLongText = (text) => {
    for (let index = 500; index < text.length; index++) {
      if (text.charAt(index) === " ") {
        return `${text.substring(0, index)}...`;
      }
    }
    return `${text}...`;
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
    if (localStorage.getItem("event")) {
      navigate("/home/detail");
    }

    try {
      fetch(Consts.baseUrl + "api/events/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(
          (res) => res.json(),
          (error) => console.error("Error ", error)
        )
        .then((res) => {
          console.log(res);
          return res;
        })
        .then((res) => setEvents(res.map((e) => new EventsModel(e))));
    } catch (error) {
      console.error("Error: ", error);
    }
  }, [navigate]);

  function selectEvent(eventId) {
    localStorage.setItem("event", eventId);
    fetch(Consts.baseUrl + "api/events/" + localStorage.getItem("event"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        return res;
      })
      .then((res) => setEvent(new EventModel(res)));
    navigate("/home/detail");
  }

  if (!events)
    return (
      <div
        className="spinner-border"
        role="status"
        style={{
          "--bs-spinner-width": "5rem",
          "--bs-spinner-height": "5rem",
          "--bs-spinner-border-width": "0.5em",
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  else
    return (
      <div className="event_wrapper">
        <div
          style={{
            paddingTop: 20,
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2 style={{ alignSelf: "flex-start" }}>ESEMÉNYEK:</h2>
          {events?.map((e) => {
            // console.log(e)
            return (
              <div
                className="card mb-3 events"
                style={{}}
                key={e.id}
                onClick={() => selectEvent(e.id)}
              >
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={e.pictureMedium}
                      className="img-fluid rounded-start"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">{e.title}</h5>
                      <p className="card-text">
                        {e.startDate.year}. {e.startDate.month.toLowerCase()}{" "}
                        {e.startDate.day}.
                      </p>
                      <p className="card-text">
                        {e.description.length > 500
                          ? handleLongText(e.description)
                          : e.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
}
