import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { Consts } from "../Consts";
import { EventModel } from "../model/Event";
import { EventsModel } from '../model/Events'

export function Archives() {
  const {setEvent} = useOutletContext()
  const navigate = useNavigate()
  const [events, setEvents] = useState()

  useEffect(() => {
    if(!localStorage.getItem('token')) {
      navigate('/')
    }
    if(localStorage.getItem('event')) {
      navigate('/home/detail')
    }

    fetch(Consts.baseUrl + "api/events/archives", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then(res => res.json())
    .then(res => setEvents(res.map(e => new EventsModel(e))))
  }, [navigate])

  function selectEvent(eventId) {
    localStorage.setItem('event', eventId)
    fetch(Consts.baseUrl + "api/events/"+localStorage.getItem('event'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then(res => res.json())
    .then(res => {console.log(res); return res})
    .then(res => setEvent(new EventModel(res)))
    navigate('/home/detail')
  }

  if(!events)
    return (
      <div className="spinner-border" role="status" style={{"--bs-spinner-width": "5rem", "--bs-spinner-height": "5rem", "--bs-spinner-border-width": "0.5em"}}>
        <span className="visually-hidden">Loading...</span>
      </div>
    )
  else
    return (
      <div class="event_wrapper">
        <div style={{paddingTop: 20, alignSelf: 'center', display: 'flex', flexDirection: 'column'}}>
        <h2 style={{alignSelf: 'flex-start'}}>ESEMÉNYEK:</h2>
          {
            events?.map(e => {
              return (
                <div className="card mb-3 archives" style={{}} key={e.id} onClick={() => selectEvent(e.id)}>
                  <div className="row g-0">
                    <div className="col-md-4">
                      <img src={e.pictureMedium} className="img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8 archives_info">
                      <div className="card-body">
                        <h5 className="card-title">{e.title}</h5>
                        <p className="card-text">{e.startDate.year} {e.startDate.month} {e.startDate.day}.</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
}
