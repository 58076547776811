import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Consts } from "../../Consts";
import { PropertiesPanel } from './PropertiesPanel';
import { UpperBackupRowContainer } from "./UpperBackupRowContainer";

export const UpperRowContainer = (props) => {
    const roundIndexes = [0, 1, 2];
    const [showPropertiesPanel, setShowPropertiesPanel] = useState(false);
    const [apiCallOngoing, setApiCallOngoing] = useState(props.apiCallOngoing);

    function togglePropertiesPanel() {
        setShowPropertiesPanel(!showPropertiesPanel);
    }

    useEffect(() => {
        setApiCallOngoing(props.apiCallOngoing);
    }, [props.apiCallOngoing]);

    return (
        <div className={styles.upperRowContainer}>
            {apiCallOngoing && <div className={styles.loadingLine}></div>}
            <div className={styles.roundTabsContainer}>
                <div
                    className={`${styles.roundTab} ${
                        props.newChanges && styles.buttonAttention
                    } ${apiCallOngoing && styles.disabledButton}`}
                    style={{ width: "220px" }}
                >
                    <button
                        style={{ flex: 1, height: '100%' }}
                        onClick={props.refreshParticipantList}
                        disabled={apiCallOngoing}
                    >
                        Frissítés
                    </button>
                </div>
                {/* {!isNaN(props.selectedRound) &&
          roundIndexes.map((round) => (
            <div
              key={round}
              onClick={() => props.setSelectedRound(round)}
              className={`${styles.roundTab} ${
                round === props.selectedRound ? styles.roundTabSelected : ""
              }`}
            >
              {round + 1}. kör
            </div>
          ))} */}
                <div className={`${styles.roundTab} ${(!props.seatStarted || apiCallOngoing) && styles.disabledButton}`}>
                    <button style={{ flex: 1, height: '100%' }} onClick={() => {
                        if (props.seatStarted) props?.generateOthersRounds?.();
                    }} disabled={!props.seatStarted || apiCallOngoing}>Generálás
                    </button>
                </div>
                <div
                    className={`${styles.roundTab} ${
                        props.notSavedChanges && styles.buttonAttention
                    } ${(!props.seatStarted || apiCallOngoing) && styles.disabledButton}`}
                    style={{ width: "170px" }}
                >
                    <button style={{ flex: 1, height: '100%' }} onClick={() => {
                        if (props.seatStarted) props?.uploadChanges?.();
                    }} disabled={!props.seatStarted || apiCallOngoing}>Mentés
                    </button>
                </div>
                <div className={`${styles.roundTab} ${(!props.seatStarted || !props.generated || apiCallOngoing) && styles.disabledButton}`}
                     style={{ width: "270px" }}>
                    <button
                        style={{ flex: 1, height: '100%' }}
                        onClick={() => {
                            if (props.seatStarted && props.generated)
                                props.pushNotifications();
                        }}
                        disabled={!props.seatStarted || !props.generated || apiCallOngoing}
                    >
                        Üzenetek kiküldése
                    </button>
                </div>
                <div className={`${styles.roundTab} ${(!props.seatStarted || !props.generated || apiCallOngoing) && styles.disabledButton}`}>
                    <button style={{ flex: 1, height: '100%' }} disabled={!props.seatStarted || !props.generated || apiCallOngoing}
                            onClick={async () => {
                                const res = await fetch(`${Consts.baseUrl}api/seat-order/event/${localStorage.getItem('event')}/seatorderexport`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                    },
                                }).then(res => res.json());
                                let anchor = document.createElement("a");
                                anchor.href = Consts.baseUrl + res.path.slice(1);
                                // console.log(Consts.baseUrl+res.path.slice(1))
                                anchor.download = res.path.split('/').slice(-1);
                                document.body.appendChild(anchor);
                                anchor.click();
                                document.body.removeChild(anchor);
                            }}>Exportálás
                    </button>
                </div>
                <div className={`${styles.roundTab} ${apiCallOngoing && styles.disabledButton}`}>
                    <button style={{ flex: 1, height: '100%' }} disabled={apiCallOngoing} onClick={togglePropertiesPanel}>
                        Paraméterek
                    </button>
                </div>
            </div>
            <UpperBackupRowContainer
                    apiCallOngoing={props.seatOrderSaveOngoing}
                    seatStarted={props.seatStarted}
                    isLocalBackupChecked={props.isLocalBackupChecked}
                    setIsLocalBackupChecked={props.setIsLocalBackupChecked}
                    setIsSeatOrderBackupModalOpen={props.setIsSeatOrderBackupModalOpen}
                />
            {(isNaN(props.selectedRound) || props.selectedRound === null) && (
                <div className={styles.errorDiv}></div>
            )}
            {showPropertiesPanel &&
                <PropertiesPanel meetingCriteria={props.meetingCriteria}
                                 meetingCriteriaChanged={props.meetingCriteriaChanged}
                                 meetingCriteriaUpdateOngoing={setApiCallOngoing}
                                 notSavedSeatOrderChangesExist={props.notSavedChanges}
                                 apiCallOngoing={apiCallOngoing}/>
            }
        </div>
    );
};
