import { Consts } from "../Consts";

// GET users of an event
export async function fetchUsers() {
    try {
        return await fetch(
            Consts.baseUrl +
            "api/event-registrations/event/" +
            localStorage.getItem("event") +
            "?states=Appeared,Registered,Canceled",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
    } catch (error) {
        console.error("Error during fetching participants: ", error);
    }
}

// GET users of an event
export async function fetchUsersRegistered() {
    try {
        const newRegs = await fetch(
            Consts.baseUrl +
            "api/event-registrations/byevent/" +
            localStorage.getItem("event") +
            "/Registered",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        return newRegs;
    } catch (error) {
        console.error("Error during fetching participants: ", error);
    }
}

// GET appeared users of an event
export async function fetchUsersAppeared() {
    try {
        const appeared = await fetch(
            Consts.baseUrl +
            "api/event-registrations/byevent/" +
            localStorage.getItem("event") +
            "/Appeared",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        return appeared;
    } catch (error) {
        console.error("Error during fetching appeared participants: ", error);
    }
}

// GET cancelled users of an event
export async function fetchUsersCancelled() {
    try {
        const cancelled = await fetch(
            Consts.baseUrl +
            "api/event-registrations/byevent/" +
            localStorage.getItem("event") +
            "/Canceled",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        return cancelled;
    } catch (error) {
        console.error("Error during fetching appeared participants: ", error);
    }
}

// PUT upload the changes of an event
export async function putSeatOrderUpdates(seatOrderRaw, setIsSaveSuccessful) {
    try {
        const putSeatOrder = await fetch(
            `${Consts.baseUrl}api/events/${localStorage.getItem("event")}/seat-order`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: JSON.stringify(seatOrderRaw),
            }
        );
        return putSeatOrder;
    } catch (error) {
        setIsSaveSuccessful("Nem sikerült a mentés");
        console.error("Error: ", error);
    }
}

// GET seatorders of an event
export async function fetchSeatOrdersRaw() {
    try {
        const seatOrderRaw = await fetch(
            Consts.baseUrl + "api/events/" + localStorage.getItem("event"),
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        return seatOrderRaw;
    } catch (error) {
        console.error("Error during fetching seat orders: ", error);
    }
}

// PUT upload the changes of the push notifications
export async function putSendPushNotifications() {
    try {

        const putSendPushNotis = await fetch(
            `${Consts.baseUrl}api/seat-order/event/${localStorage.getItem("event")}/sendnotification`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        return putSendPushNotis;
    } catch (error) {
        console.error("Error: ", error);
    }
}
