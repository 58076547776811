import Modal from '../../components/Modal';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { saveEventMeetingCriteria } from '../../services/EventService';

export const PropertiesPanel = (props) => {

    const [meetingCriteria, setMeetingCriteria] = useState(props.meetingCriteria);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [notSavedSeatOrderChangesExist, setNotSavedSeatOrderChangesExist] = useState(props.notSavedSeatOrderChangesExist);
    const [apiCallOngoing, setApiCallOngoing] = useState(props.apiCallOngoing);

    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        setMeetingCriteria(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    useEffect(() => {
        setNotSavedSeatOrderChangesExist(props.notSavedSeatOrderChangesExist);
    }, [props.notSavedSeatOrderChangesExist]);

    useEffect(() => {
        setApiCallOngoing(props.apiCallOngoing);
    }, [props.apiCallOngoing]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        props.meetingCriteriaUpdateOngoing(true);

        if (notSavedSeatOrderChangesExist) {
            setModalMessage("Nem mentett változások vannak az ülésrendben.");
            setModalOpen(true);
            setLoading(false);
            props.meetingCriteriaUpdateOngoing(false);
        } else {
            saveEventMeetingCriteria(meetingCriteria, setModalMessage)
                .then(() => props.meetingCriteriaChanged())
                .finally(() => {
                    setModalOpen(true);
                    setLoading(false);
                    props.meetingCriteriaUpdateOngoing(false);
                });
        }
    };

    return (
        <div className={styles.propertiesContainerWrapper}>
            <form className={styles.propertiesContainer} onSubmit={handleSubmit}>
                <div className={styles.propertyInputGroup}>
                    <label htmlFor="months">Hónapok száma:</label>
                    <input id="months" name="meetingWithinMonths" type="number" min={1} value={meetingCriteria.meetingWithinMonths} onChange={handleChange}/>
                </div>

                <div className={styles.propertyInputGroup}>
                    <label htmlFor="meetings">Találkozók száma:</label>
                    <input id="meetings" name="meetingCount" type="number" min={1} value={meetingCriteria.meetingCount} onChange={handleChange}/>
                </div>

                <div className={styles.propertyInputGroup}>
                    <label htmlFor="active">Aktív:</label>
                    <input id="active" name="checkMeetingConflict" type="checkbox" checked={meetingCriteria.checkMeetingConflict}
                           onChange={handleChange}/>
                </div>

                <div className={styles.propertyInputGroup}>
                    <div className={styles.saveButton}>
                        <button type="submit" value="Mentés" disabled={loading || apiCallOngoing}>Mentés</button>
                    </div>
                </div>
            </form>
            <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                title={"Mentés"}
                body={modalMessage}
                isError={modalMessage.startsWith("Sikertelen")}
                isSuccess={modalMessage.startsWith("Sikeres")}
                onCloseTitle={"Rendben"}>
            </Modal>
        </div>
    );
};
