import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { Consts } from "../Consts";
import SynchronizationButton from "../components/SynchronizationButton";
import noProfilePhotoIcon from "../assets/no-profile-photo.png";
import { localizeState } from "../utils/registrationRenderHelpers";

export function NewRegistrations() {
  const { event } = useOutletContext();

  const [res, setRes] = useState([]);
  useEffect(() => console.log("USER RESULT CHANGED:", res), [res])

  const [regs, setRegs] = useState(null);
  useEffect(() => console.log("REGISTRATIONS CHANGED:", regs), [regs])

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetch(
      Consts.baseUrl +
        "api/event-registrations/findbyevent/" +
        localStorage.getItem("event"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then(async (res) => setRegs(await res.json()));
  }, []);

  if (!event || !regs)
    return (
      <div
        className="spinner-border"
        role="status"
        style={{
          "--bs-spinner-width": "5rem",
          "--bs-spinner-height": "5rem",
          "--bs-spinner-border-width": "0.5em",
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    );

  const search = () => {
    fetch(Consts.baseUrl + "api/users/?s=" + searchText, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then(async (res) => {
      console.log(res)
      setRes(
        (await res.json()).map(u => {return {
          id: u.id,
          picture: u.picture,
          name: `${u.lastName} ${u.firstName}`,
          companyName: u.company.name,
          reservationConflictId: u.company.reservationConflictId,
        }}).sort((a, b) => a.name.localeCompare(b.name))
      )
    });
  };

  function insertOrUpdateRegistration(userId, state) {
    fetch(`${Consts.baseUrl}api/event-registrations/adminRegister/${localStorage.getItem("event")}/${userId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        state: state,
      }),
    }).then(res => res.json()).then(res => {
      console.log(res)
      const reg = regs.find(r => r.user.id === userId)
      if(reg) reg.state = res.registration.state
      else regs.push(res.registration)
      setRegs([...regs])
    })
  }
  const syncWithCRM = () => {
    fetch(Consts.baseUrl + "api/crm/sync-users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log("RES: ", JSON.stringify(res));
      });
  }

  console.log(res)

  return (
    <div className="row wrapper">
      <div className="new-registration-upper-div">
        <h2>ÚJ RÉSZTVEVŐ HOZZÁADÁSA:</h2>
        <div className="new-registration-upper-div-upper-row">
          <div className="new-registration-upper-div-lower-row">
            <input
              className="inputSearch"
              type="search"
              onChange={(e) => setSearchText(e.target.value)}
            />
            <input
              className="searchbutton"
              type="button"
              value="Keresés"
              onClick={search}
            />
          </div>
          <SynchronizationButton onSync={syncWithCRM}/>
        </div>
      </div>

      {res && (
        <table className="new-registration table table-bordered">
          <thead>
            <tr key="header">
              <th scope="col">Státusz</th>
              <th scope="col">Fotó</th>
              <th scope="col">Név</th>
              <th scope="col">Cégnév</th>
              <th scope="col">Ütközés ID</th>
              <th scope="col">Ügyintézés</th>
            </tr>
          </thead>
          <tbody>
            {res?.map((u, index) => {
              const state = regs.find(r => r.user.id === u.id)?.state ?? null
              return (
                <tr key={index}>
                  <td>{localizeState(state)}</td>
                  <td className="imgwrapper" scope="row">
                    <img
                      src={
                        (u.picture && u.picture?.url)
                          ? Consts.baseUrl + u.picture.url.slice(1)
                          : noProfilePhotoIcon
                      }
                      className="img-fluid rounded"
                      alt="..."
                    />
                  </td>
                  <td>
                    {u.name}
                  </td>
                  <td>{u.companyName ?? "Nincs cég megadva"}</td>
                  <td style={{ textAlign: "center" }} >{u.reservationConflictId}</td>
                    {
                      !state || state === "Canceled" ?
                        <td>
                          <input
                            id="register"
                            type="button"
                            title="Regisztrál"
                            onClick={() => insertOrUpdateRegistration(u.id, "Registered")}
                          />
                          <input
                            id="login"
                            type="button"
                            title="Beléptet"
                            onClick={() => insertOrUpdateRegistration(u.id, "Appeared")}
                          />
                        </td>
                      : state === "Registered" ?
                        <td>
                          <input
                            id="show"
                            className="button"
                            type="button"
                            title="Megjelent"
                            onClick={() => insertOrUpdateRegistration(u.id, "Appeared")}
                          />
                          <input
                            id="cancel"
                            className="button"
                            type="button"
                            title="Lemondta"
                            onClick={() => insertOrUpdateRegistration(u.id, "Canceled")}
                          />
                        </td>
                      :
                        <td></td>
                    }
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
