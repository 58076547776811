export const colors = [
    "#E8D0A9",
    "#B7AFA3",
    "#C1DAD6",
    "#ACD1E9",
    "#6D929B",
    "#FF6961",
    "#A9D39E",
    "#C89ED3",
    "#FDFD96",
    "#F3CFC6",
    "#C6F3CF",
    "#C6F3E6",
    "#D4F3C6",
    "#EAF3C6",
    "#FFFACD",
    "#FFF44F",
    "#4FB2FF",
    "#4FFF9C",
    "#4FFFF4",
    "#5AFF4F",
    "lightcoral",
  ];