import { Consts } from "../Consts";

export async function saveEventMeetingCriteria(meetingCriteria, resultsCallback) {
    try {
        const updatedMeetingCriteria = await fetch(
            `${Consts.baseUrl}api/events/${localStorage.getItem("event")}/meeting-criteria`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: JSON.stringify(meetingCriteria),
            }
        );

        resultsCallback("Sikeres mentés");

        return updatedMeetingCriteria;
    } catch (error) {
        resultsCallback("Sikertelen mentés");
        console.error("Error: ", error);
    }

}
