import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { Table } from "./Table";
import CloseIcon from "../../assets/icons/close-icon.png";
import MinusIcon from "../../assets/icons/minus-icon.png";

export const TablesContainer = (props) => {
  const roundIndexes = [0, 1, 2];
  const indexToAlpha = (num = 1) => {
    const A = "A".charCodeAt(0);
    let numberToCharacter = (number) => {
      return String.fromCharCode(A + number);
    };
    return numberToCharacter(num);
  };

  useEffect(() => {
    props.seatOrder &&
    props.seatOrder.rounds[props.selectedRound].tables.sort((a, b) => a.id - b.id)
    props.seatOrder.rounds[props.selectedRound].tables.forEach(table => table.seats[0].sort((a, b) => a.id - b.id));
  }, [props]);

  return (
    <div className={`${styles.tablesContainer}`}>
      <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
        <div style={{ display: "flex", flexDirection: "row", gap: '20px', flex: 1, alignItems: "flex-start" }}>
          {props.seatOrder.rounds.length !== 0 &&
            props.seatOrder.rounds[props.selectedRound].tables &&
            props.seatOrder.rounds[props.selectedRound].tables.map(
              (table, index) => (
                <Table
                  key={indexToAlpha(index)}
                  index={indexToAlpha(index)}
                  table={table}
                  handleTables={props.handleTables}
                  handleSeatsSameTotal={props.handleSeatsSameTotal}
                  selectedRound={props.selectedRound}
                  handleParticipantSelection={props.handleParticipantSelection}
                  allParticipants={props.allParticipants}
                  selectedParticipant={props.selectedParticipant}
                  handleSelectedParticipant={props.handleSelectedParticipant}
                  removeFromTableSelectedParticipant={
                    props.removeFromTableSelectedParticipant
                  }
                />
              )
            )}
          </div>
          <div className={styles.addingNewTableDiv}>
            <div style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: 'center' }}>
              <button
                className={`${styles.button} ${styles.buttonWithTooltip} ${styles.indicator} ${styles.hoverText}`}
                onClick={() => {
                  props.handleTablesChange(1);
                }}
              >
                <span
                  className={styles.tooltipText}
                  style={{ position: "absolute", bottom: "50px", left: "-20px" }}
                >
                  új asztal hozzáadása
                </span>
                <img
                  className={styles.iconAddingNew}
                  src={CloseIcon}
                  alt="új asztal hozzáadása"
                />
              </button>
              <button
                className={`${styles.button} ${styles.buttonWithTooltip} ${styles.indicator} ${styles.hoverText}`}
                onClick={() => {
                  props.handleTablesChange(-1);
                }}
              >
                <span
                  className={styles.tooltipText}
                  style={{ position: "absolute", bottom: "50px", left: "-60px" }}
                >
                  asztal elvétele
                </span>
                <img
                  className={styles.iconMinus}
                  src={MinusIcon}
                  alt="asztal elvétele"
                />
              </button>
            </div>
          </div>
    </div>

        <div style={{ visibility: props.seatOrder.rounds[props.selectedRound].tables.length > 0 ? "visible" : "hidden" }} className={styles.addingNewTableDiv}>
          <button
            className={`${styles.button} ${styles.indicator} ${styles.hoverText}`}
            onClick={() => {
              props.handleSeatsSameTotal(1);
            }}
          >
            <span
              className={styles.tooltipText}
              style={{ position: "relative", bottom: "50px", left: "70px" }}
            >
              új székek hozzáadása
            </span>
            <img
              className={styles.iconAddingNew}
              src={CloseIcon}
              alt="új szék hozzáadása"
            />
          </button>
          <button
            className={`${styles.button} ${styles.indicator} ${styles.hoverText}`}
            onClick={() => {
              props.handleSeatsSameTotal(-1);
            }}
          >
            <img
              className={styles.iconMinus}
              src={MinusIcon}
              alt="szék elvétele"
            />
            <span
              className={styles.tooltipText}
              style={{ position: "relative", bottom: "50px", right: "80px" }}
            >
              székek elvétele
            </span>
          </button>
        </div>
      </div>
  );
};
