import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { Consts } from "../Consts";
import noProfilePhotoIcon from "../assets/no-profile-photo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen, faXmark } from "@fortawesome/free-solid-svg-icons";

export function OnSite() {
  const {event} = useOutletContext()
  const [regs, setRegs] = useState(null)
  const [lunchEditors, setLunchEditors] = useState({})

  function setLunch(index, value) {
    lunchEditors[index] = value;
    setLunchEditors({...lunchEditors});
  }

  async function saveLunch(index) {
    await fetch(
      Consts.baseUrl + "api/event-registrations/update/" + index,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          lunch: lunchEditors[index]
        })
      }
    ).then(() => updateRegistrations());
    lunchEditors[index] = undefined;
    setLunchEditors({...lunchEditors});
  }

  useEffect(() => {
    updateRegistrations()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      updateRegistrations()
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  const updateRegistrations = () => {
    fetch(Consts.baseUrl + "api/event-registrations/byevent/"+localStorage.getItem('event')+"/Appeared", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then(res => res.json())
    .then(res => setRegs(res))
  }

  if(!regs || !event)
    return (
      <div className="spinner-border" role="status" style={{"--bs-spinner-width": "5rem", "--bs-spinner-height": "5rem", "--bs-spinner-border-width": "0.5em"}}>
        <span className="visually-hidden">Loading...</span>
      </div>
    )

  return (
    <div className="row wrapper">
      <div className="col-6">
        <h2>HELYSZÍNEN MEGJELENTEK:</h2>
        <table className="onsite mtop25 table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col">Fotó</th>
              <th scope="col">Név</th>
              <th scope="col">Cégnév</th>
              <th style={{
                display:
                  !event?.addresses?.find(
                    (a) => !a.location || a.location === ""
                  ) && "none",
              }} scope="col">Online</th>
              <th style={{ display: !event.lunch && "none" }} scope="col">Ebéd</th>
              <th scope="col">Ütközés ID</th>
            </tr>
          </thead>
          <tbody>
          {
            regs ? regs?.map((r, index) => {
              return (
                <tr key={index}>
                  <td className="imgwrapper" scope="row"><img src={ r.user.picture?.url ? Consts.baseUrl + r.user.picture.url.slice(1) : noProfilePhotoIcon } ></img></td>
                  <td>{r.user.lastName} {r.user.firstName}</td>
                  <td>{r.user.company?.name ?? "Nincs cég megadva"}</td>
                  <td style={{
                    display:
                      !event?.addresses?.find(
                        (a) => !a.location || a.location === ""
                      ) && "none",
                  }} scope="col">{r.online ? 'Igen':'Nem'}</td>
                  <td style={{ display: !event.lunch && "none" }} scope="col">
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                      { lunchEditors[r.id] === undefined ?
                          <div style={{width: 100}}>{r.lunch ? "Igen" : "Nem"}</div> :
                          <div style={{width: 100}}>
                            <select value={lunchEditors[r.id]} onChange={event => setLunch(r.id, event.target.value)} style={{width: '100%'}}>
                              <option value="true">Igen</option>
                              <option value="false">Nem</option>
                            </select>
                          </div>
                      }
                      { lunchEditors[r.id] === undefined ?
                          <FontAwesomeIcon icon={faPen} onClick={() => setLunch(r.id, r.lunch ?? false)} style={{marginLeft: 20}} /> :
                          <div style={{display: "flex"}}>
                            <FontAwesomeIcon icon={faCheck} onClick={() => saveLunch(r.id)} style={{marginLeft: 20}} />
                            <FontAwesomeIcon icon={faXmark} onClick={() => setLunch(r.id, undefined)} style={{marginLeft: 5}} />
                          </div>
                      }
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }} >{r.user?.company?.reservationConflictId}</td>
                </tr>)
            }) : <div>Nincs még helyszínen megjelent személy</div>
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}
