export const categories = [
  {
    name: "100000000",
    color: "lemonchiffon",
    text: "100",
    tooltip: "100 millió felett",
  },
  {
    name: "400000000",
    color: "lightyellow",
    text: "400",
    tooltip: "400 millió felett",
  },
  {
    name: "1000000000",
    color: "yellow",
    text: "1M",
    tooltip: "1 milliárd felett",
  },
  { 
    name: "5000000000", 
    color: "gold", 
    text: "5M", 
    tooltip: "5 milliárd felett" 
  },
];
