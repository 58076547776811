import React, { useEffect, useState, useRef } from "react"
import { useOutletContext } from "react-router"
import { stateNameAndValues } from "../utils/registrationRenderHelpers"
import { Consts } from "../Consts"
import Modal from '../components/Modal'

const MAX_LENGTH_TITLE = 50
const MAX_LENGTH_MESSAGE = 300

export function SendNotification() {
  const { event } = useOutletContext()
  const [selectedState, setSelectedState] = useState(stateNameAndValues.keys().next().value)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)
  const isSentDeviceSuccess = useRef(null)

  const isButtonDisabled = loading || message.length === 0 || title.length === 0

  useEffect(() => {
    console.log(message)
    if (message.indexOf('\n') !== -1) {
      setMessage(message.replace('\n', ' '))
    }
  }, [message])

  if (!event)
    return (
      <div
        className="spinner-border"
        role="status"
        style={{
          "--bs-spinner-width": "5rem",
          "--bs-spinner-height": "5rem",
          "--bs-spinner-border-width": "0.5em",
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    )

  const sendNotifications = async () => {
    setLoading(true)
    isSentDeviceSuccess.current = null
    try {
      const response = await fetch(
        `${Consts.baseUrl}api/events/${localStorage.getItem("event")}/send-custom-notification`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({
            state: selectedState,
            title: title,
            message: message,
          }),
        }
      )
      isSentDeviceSuccess.current = response.ok
    } catch (error) {
      console.error('sendNotifications', error)
    }

    setFeedbackModalOpen(true)
    setLoading(false)

    if (isSentDeviceSuccess.current) {
      setTitle('')
      setMessage('')
    }
  }

  return (
    <div className="col">
      <div className="eventbottomwrapper">
        <h2>ÉRTESÍTÉS KÜLDÉSE</h2>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>
                Címzettek
              </td>
              <td>
                <select
                  value={selectedState}
                  onChange={e => setSelectedState(e.target.value)}
                >
                  {
                    Array.from(stateNameAndValues.entries()).map((state, index) => {
                      return (
                        <option key={index} value={state[0]}>{state[1]}</option>
                      )
                    })
                  }
                </select>
              </td>
            </tr>
            <tr>
              <td className="top-aligned-text minimal-width">
                Cím
              </td>
              <td>
                <input
                  type="text"
                  value={title}
                  className="maximal-width"
                  onChange={(e) => setTitle(e.target.value)}
                  maxLength={MAX_LENGTH_TITLE}
                />
                <div className="end-aligned-text">
                  {MAX_LENGTH_TITLE - title.length} karakter maradt
                </div>
              </td>
            </tr>
            <tr>
              <td className="top-aligned-text minimal-width">
                Szöveg
              </td>
              <td>
                <textarea
                  value={message}
                  className="maximal-width"
                  style={{ minHeight: '100px' }}
                  onChange={(e) => setMessage(e.target.value)}
                  maxLength={MAX_LENGTH_MESSAGE}
                />
                <div className="end-aligned-text">
                  {MAX_LENGTH_MESSAGE - message.length} karakter maradt
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="end-aligned-text">
          <input
            className={'button ' + ((loading || isButtonDisabled) && 'button-disabled')}
            type="button"
            value={loading ? "Küldés..." : "Küldés"}
            onClick={() => { setConfirmModalOpen(true) }}
            disabled={isButtonDisabled}
          />
        </div>
      </div>
      <Modal
        isOpen={feedbackModalOpen}
        onClose={() => setFeedbackModalOpen(false)}
        title={"Értesítések küldése"}
        body={isSentDeviceSuccess.current ? `Az üzenetek kiküldését sikeresen elindítottuk.` : 'Az üzenetek kiküldését nem sikerült elindítanunk.'}
        isError={!isSentDeviceSuccess.current}
        isSuccess={isSentDeviceSuccess.current}
        onCloseTitle={"Rendben"}>
      </Modal>
      <Modal
        isOpen={confirmModalOpen}
        onClose={() => { setConfirmModalOpen(false) }}
        title={"Megerősítés"}
        body={"Biztosan elküldi az értesítéseket?"}
      >
        <div className="modal-footer">
          <button
            onClick={() => {
              setConfirmModalOpen(false)
            }}
            className="modal-close-button"
          >
            Mégsem
          </button>
          <button
            onClick={() => {
              setConfirmModalOpen(false)
              sendNotifications()
            }}
            className="button"
          >
            Küldés
          </button>
        </div>
      </Modal>
    </div>
  )
}
