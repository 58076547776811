import React, { useEffect, useState, useRef } from "react";
import SyncIcon from "../assets/sync.svg";

const SynchronizationButton = (props) => {
  const timeForBeDisabledInMins = 2;
  const [disabled, setDisabled] = useState(false);
  const [timer, setTimer] = useState("");
  const ref = useRef(null);

  const getRemainingTime = () => {
    const latestTimeStampInStorage = localStorage.getItem("syncbuttontime");
    if (!latestTimeStampInStorage) {
      return -1;
    }
    const latestDate = new Date(latestTimeStampInStorage);
    const now = new Date();

    return (now.getTime() - latestDate.getTime()) / 1000;
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);

    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      setDisabled(false);
    }
  };

  const clearTimer = (e) => {
    if (ref.current) clearInterval(ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(
      deadline.getSeconds() +
        (timeForBeDisabledInMins * 60 - getRemainingTime())
    );
    return deadline;
  };

  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  const handleTimer = () => {
    const now = new Date();

    localStorage.setItem("syncbuttontime", now.toISOString());
    setDisabled(true);
    onClickReset();
  };
  useEffect(() => {
    getRemainingTime() === -1 || getRemainingTime > timeForBeDisabledInMins * 60
      ? setDisabled(false)
      : setDisabled(true);
    clearTimer(getDeadTime());
  }, []);

  return (
    <div>
      <button
        type="button"
        disabled={disabled}
        className="button"
        style={{ width: "410px", padding: "10px 30px", backgroundColor: disabled && "whitesmoke", color: disabled && "gray" }}
        onClick={() => {
          handleTimer();
          props.onSync();
        }}
      >
        {disabled
          ? `Kézi szinkornizáció újra lehetséges ekkor: ${timer}`
          : "KÉZI SZINKRONIZÁCIÓ INDÍTÁSA"}
        {!disabled && <img style={{ width: "20px", height: "20px", position: "relative", top: "-2px"}} className="iconSyncButton" alt="sync-icon" src={SyncIcon} />}
      </button>
    </div>
  );
};

export default SynchronizationButton;
