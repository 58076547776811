import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "../App.css";
import { Consts } from "../Consts";

export function Login(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/home");
    }
  });

  async function getMe(token) {
    const response = await fetch(Consts.baseUrl + "api/users/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return response;
  }

  async function login() {
    await fetch(Consts.baseUrl + "api/auth/local", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: email,
        password,
      }),
    })
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            getMe(data.jwt).then((meRes) => {
              meRes.json().then((meData) => {
                if (meData.role.type === "admin") {
                  localStorage.setItem("token", data.jwt);
                  navigate("/home");
                } else {
                  alert("Ön nem Admin jogosultsággal próbál belépni!");
                }
              });
            });
          });
        } else {
          console.error("Login Error", res);
          alert("Rossz felhasználónév vagy jelszó!");
        }
      })
      .catch((e) => console.error("Login error:", e));
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="col-6">
          <img
            src="/MVUK_logo_2017-removebg-preview_white.png"
            alt="logo"
            style={{ width: "40%", marginBottom: 100 }}
          />
          <div style={{ flex: 1 }}>
            <div className="row">Email address:</div>
            <div className="row">
              <input
                type="email"
                className="form-control"
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginBottom: 15 }}
                value={email}
              />
            </div>
            <div className="row">Password:</div>
            <div className="row">
              <input
                type="password"
                className="form-control"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <div className="row">
              <input
                type="button"
                className="btn btn-primary"
                value="Bejelentkezés"
                onClick={() => login()}
                style={{ marginTop: 25, marginBottom: 300 }}
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

