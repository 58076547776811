import React, { Fragment, useEffect, useRef, useState } from "react";
import { Participant } from "../../model/Participant";
import { ParticipantsPoolTable } from "./ParticipantsPoolTable";
import styles from "./styles.module.css";
import { TablesContainer } from "./TablesContainer";
import { UpperRowContainer } from "./UpperRowContainer";
import { Seat as SeatModel } from "../../model/Seat";
import { Table as TableModel } from "../../model/Table";
import { Round as RoundModel } from "../../model/Round";
import { SeatOrder as SeatOrderModel } from "../../model/SeatOrder";
import Modal from "../../components/Modal";
import { colors } from "../../configs/colorsForConflictIDs";
import { fetchSeatOrdersRaw, fetchUsers, putSeatOrderUpdates, putSendPushNotifications, } from "../../services/SeatOrderService";
import { usePrompt } from "../../hooks/usePrompt";

class SeatOrderBackupStorage {
    static PREFIX = 'seatorder'
    static DELIMITER = '/'

    static add(eventid, contentObj) {
        this.clearOldEntries()

        try {
            const key = `${this.PREFIX}${this.DELIMITER}${eventid}${this.DELIMITER}${new Date().toISOString()}`
            const contentJson = JSON.stringify(contentObj)
            localStorage.setItem(key, contentJson)
            return true
        } catch (e) {
            console.error(e)
            return false
        }
    }

    static list(eventid) {
        const result = this.listRelevantKeys()
            .filter((keyInfo) => keyInfo.keySegments[1] === eventid)
            .map((keyInfo) => {
                try {
                    const contentJson = localStorage.getItem(keyInfo.key)
                    const contentObj = JSON.parse(contentJson)
                    return {
                        ...keyInfo,
                        //key: keyInfo.key,
                        //keySegments: keyInfo.keySegments,
                        contentJson,
                        contentObj,
                    }
                } catch (e) {
                    console.error(e)
                    return null
                }
            })
            .filter((item) => !!item)
        return result.length === 0 ? null : result
    }

    static clearOldEntries() {
        this.listRelevantKeys()
            .forEach(keyInfo => {
                const isOldEntry = Date.now() - Date.parse(keyInfo.keySegments[2]) > 30 * 24 * 60 * 60 * 1000
                if (isOldEntry)
                    localStorage.removeItem(keyInfo.key)
            });
    }

    static listRelevantKeys() {
        const result = []
        try {
            const keyCount = localStorage.length
            for (var keyIdx = 0; keyIdx < keyCount; keyIdx++) {
                const key = localStorage.key(keyIdx)
                const keySegments = key.split(this.DELIMITER)
                if (keySegments.length == 3 && keySegments[0] === this.PREFIX)
                    result.push({ key, keySegments })
            }
        } catch (e) {
            console.error(e)
        }
        return result
    }
}

export const SeatOrder = () => {
    // const [seatOrderRawSaved, setSeatOrderRawSaved] = useState(null);
    const [seatOrderRaw, setSeatOrderRaw] = useState(null);
    const [seatOrder, setSeatOrder] = useState(null);
    const [meetingCriteria, setMeetingCriteria] = useState(null);
    const [seatStarted, setSeatStarted] = useState(false);
    const [allDisplayedParticipants, setAllDisplayedParticipants] = useState(null);
    const [registeredParticipants, setRegisteredParticipants] = useState(null);
    const [appearedParticipants, setAppearedParticipants] = useState(null);
    const [cancelled, setCancelled] = useState([]);
    const [newlyRegisteredParticipants, setNewlyRegisteredParticipants] =
        useState(null);
    const [newlyAppearedParticipants, setNewlyAppearedParticipants] =
        useState(null);
    // const [newlyAllParticipants, setNewlyAllparticipants] = useState(null);
    const [newlyCancelled, setNewlyCancelled] = useState([]);
    // const participants = useRef([]);
    const [participantsInPool, setParticipantsInPool] = useState([]);
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [selectedRound, setSelectedRound] = useState(0);
    const [
        notificationForDeletingSeatAlert,
        setNotificationForDeletingSeatAlert,
    ] = useState(false);
    const [
        notificationForDeletingTableAlert,
        setNotificationForDeletingTableAlert,
    ] = useState(false);
    const [isAnyChange, setIsAnyChange] = useState(false);
    const [isNotSavedDialogueOpen, setIsNotSavedDialogueOpen] = useState(false);
    const [isSaveDialogueOpen, setIsSaveDialogueOpen] = useState(false);
    const [isSaveSuccessful, setIsSaveSuccessful] = useState("");
    const [isPushNotiDialogueOpen, setIsPushNotiDialogueOpen] = useState(false);
    const [isGeneratedRoundsDialogueOpen, setIsGeneratedRoundsDialogueOpen] =
        useState(false);
    const [
        isRefreshParticipantsListDialogueOpen,
        setIsRefreshParticipantsListDialogueOpen,
    ] = useState(false);
    const [isSeatOrderBackupModalOpen, setIsSeatOrderBackupModalOpen] = useState(false);
    const [isChangesFromBackground, setIsChangesFromBackground] = useState(false);
    // const [pushNotificationsList, setPushNotificationsList] = useState([]);
    const newChanges = useRef(false);
    const [seatOrderSaveOngoing, setSeatOrderSaveOngoing] = useState(false);
    const [isLocalBackupChecked, setIsLocalBackupChecked] = useState(true);

    const handleParticipantSelection = (participant) => {
        participant && selectedParticipant
            ? setSelectedParticipant(null)
            : setSelectedParticipant(participant);
    };

    const handleParticipants = (participants, seatOrderRaw) => {
        if (!participants || participants.length === 0 || !seatOrderRaw) {
            return;
        }

        const conflictIds = handleConflictsBetweenParticipants(participants);
        conflictIds.forEach((c, index) => {
            const sameConflictIdParticipants = participants.filter(
                (p) => +p.company?.reservationConflictId === c
            );
            sameConflictIdParticipants.forEach((p) => {
                p.conflict = getBackgroundColor(index);
            });
        });

        // setting unseated attr for each participant and in every round ([0, 1, 2])
        [0, 1, 2].forEach((round) => {
            participants.forEach((s) => {
                s.unseated[round] = !seatOrderRaw.seats.find(
                    (seat) => seat.round === round && seat.user === s.id
                );
            });
        });

        setParticipantsInPool(
            participants.filter((p) => p.unseated[selectedRound])
        );
    };

    // uploading changes to backend
    const uploadChanges = () => {
        setSeatOrderSaveOngoing(true);

        if (seatOrderRaw.id === null || !isNaN(seatOrderRaw.id)) {
            seatOrderRaw.seatOrder = seatOrderRaw.id;
            delete seatOrderRaw.id;
        }

        console.log(
            "uploadChanges PUT seatOrderRaw: ",
            JSON.stringify(seatOrderRaw)
        );

        if (isLocalBackupChecked) {
            const eventid = localStorage.getItem("event")
            SeatOrderBackupStorage.add(eventid, seatOrderRaw)
        }
        setIsLocalBackupChecked(true)

        putSeatOrderUpdates(seatOrderRaw, setIsSaveSuccessful)
            .then((res) => res.json())
            .then((json) => {
                console.log("uploadChanges ANSWER put: ", JSON.stringify(json));
                seatOrderRaw.id = json.seat_order.id;
                setSeatOrderRaw({ ...seatOrderRaw });
                setIsSaveSuccessful("A mentés sikeresen befejeződött");
                newChanges.current = false;
                setIsAnyChange(false);
            })
            .then(() => setIsSaveDialogueOpen(true))
            .finally(() => setSeatOrderSaveOngoing(false));
    };

    const updatePushNotifications = () => {
        console.log("updatePushNotifications clicked");
        putSendPushNotifications()
            .then((res) => res.json())
            .then((json) => {
                setIsPushNotiDialogueOpen(true);
                console.log("ANSWER updatePushNotifications: ", JSON.stringify(json));
            });
    };

    const getParticipants = () => {
        fetchUsers()
            .then((res) => res.json())
            .then(res => {
                res = checkAndRemoveDuplicationsInParticipantsRaw(res);
                setRegisteredParticipants([...parseParticipants(res.filter(reg => reg.state === 'Registered').filter(el => !el.online))]);
                setAppearedParticipants([
                    ...parseParticipants(res.filter(reg => reg.state === 'Appeared').filter((el) => !el.online)),
                ]);
                setAllDisplayedParticipants([
                    ...parseParticipants(res.filter(reg => reg.state === 'Registered').filter((el) => !el.online)),
                    ...parseParticipants(res.filter(reg => reg.state === 'Appeared').filter((el) => !el.online)),
                ]);
                setCancelled([...parseParticipants(res.filter(reg => reg.state === 'Canceled').filter((el) => !el.online))]);
            });
    };

    const compareChangesFromBackground = (
        registeredParticipants,
        newlyRegisteredParticipants,
        appearedParticipants,
        newlyAppearedParticipants,
        cancelled,
        newlyCancelled
    ) => {
        if (
            !registeredParticipants ||
            !newlyRegisteredParticipants ||
            !appearedParticipants ||
            !newlyAppearedParticipants ||
            !cancelled ||
            !newlyCancelled
        ) {
            return;
        }
        console.log("=1 compareChangesFromBackground ==");
        console.log(
            JSON.stringify(registeredParticipants.map((p) => p.id).sort()),
            JSON.stringify(newlyRegisteredParticipants.map((p) => p.id).sort()),
            JSON.stringify(appearedParticipants.map((p) => p.id).sort()),
            JSON.stringify(newlyAppearedParticipants.map((p) => p.id).sort()),
            JSON.stringify(cancelled.map((p) => p.id).sort()),
            JSON.stringify(newlyCancelled.map((p) => p.id).sort())
        );
        const result =
            JSON.stringify(registeredParticipants.map((p) => p.id).sort()) ===
            JSON.stringify(newlyRegisteredParticipants.map((p) => p.id).sort()) &&
            JSON.stringify(appearedParticipants.map((p) => p.id).sort()) ===
            JSON.stringify(newlyAppearedParticipants.map((p) => p.id).sort()) &&
            JSON.stringify(cancelled.map((p) => p.id).sort()) ===
            JSON.stringify(newlyCancelled.map((p) => p.id).sort());
        setIsChangesFromBackground(!result);
    };

    // checking both cancelled and all participants changes in the background
    const manageComparingParticipantsChangesInBackground = () => {
        fetchUsers()
            .then((res) => res.json())
            .then((res) => {
                setNewlyRegisteredParticipants([...parseParticipants(res.filter(reg => reg.state === 'Registered'))]);
                setNewlyAppearedParticipants([...parseParticipants(res.filter(reg => reg.state === 'Appeared'))]);
                setNewlyCancelled([...parseParticipants(res.filter(reg => reg.state === 'Canceled'))]);
            });
    };

    const refreshParticipantList = () => {
        setIsChangesFromBackground(false);
        setIsRefreshParticipantsListDialogueOpen(true);
        getParticipants();
        updateSeatOrdersRaw(
            removeParticipantsFromSeatOrderRaw(seatOrderRaw, allDisplayedParticipants)
        );
        console.log("refreshParticipantList");
        // setAllparticipants([...newlyAllParticipants])
        // setCancelled([...newlyCancelled]);
    };

    // helper function for parsing, removes accidental participant duplications
    const checkAndRemoveDuplicationsInParticipantsRaw = (res) => {
        console.log("checkAndRemoveDuplicationsInParticipantsRaw", JSON.stringify(res));
        const appearedParticipantsRaw = res.filter(reg => reg.state === 'Appeared').reduce(
            (accumulator, current) => {
                if (!accumulator.find((raw) => raw.user.id === current.user.id)) {
                    console.log("current", JSON.stringify(current));
                    accumulator.push(current);
                }
                return accumulator;
            },
            []
        );

        const registratedParticipantsRaw = res.filter(reg => reg.state === 'Registered').reduce(
            (accumulator, current) => {
                if (!accumulator.find((raw) => raw.user.id === current.user.id) && !appearedParticipantsRaw.find((raw) => raw.user.id === current.user.id)) {
                    console.log("current", JSON.stringify(current));
                    accumulator.push(current);
                }
                return accumulator;
            },
            []
        );

        const cancelledParticipantsRaw = res.filter(reg => reg.state === 'Canceled').reduce(
            (accumulator, current) => {
                if (!accumulator.find((raw) => raw.user.id === current.user.id) && !appearedParticipantsRaw.find((raw) => raw.user.id === current.user.id)) {
                    console.log("current", JSON.stringify(current));
                    accumulator.push(current);
                }
                return accumulator;
            },
            []
        );

        res = [...registratedParticipantsRaw, ...appearedParticipantsRaw, ...cancelledParticipantsRaw];
        console.log("checkAndRemoveDuplicationsInParticipantsRaw2", JSON.stringify(res));
        return res;
    };

    function updateMeetingCriteria(seatOrderRaw) {
        if (!seatOrderRaw) {
            return;
        }

        setMeetingCriteria({
            meetingWithinMonths: seatOrderRaw.meetingWithinMonths,
            meetingCount: seatOrderRaw.meetingCount,
            checkMeetingConflict: seatOrderRaw.checkMeetingConflict
        });
    }

    function parseParticipants(participantsRaw) {
        if (!participantsRaw || participantsRaw.length < 1) {
            return [];
        }

        return participantsRaw
            .filter((raw) => raw.user !== null)
            .map(
                (raw) =>
                    new Participant(
                        raw.user.id,
                        raw.user.username,
                        raw.user.email,
                        raw.user.firstName,
                        raw.user.lastName,
                        raw.user.company ? raw.user.company : "",
                        raw.user.clubs ? raw.user.clubs : [],
                        [],
                        raw.user.picture,
                        null,
                        raw.user.needChangePassword,
                        raw.state ? raw.state : "Registrated",
                        null,
                        raw.user.tags
                    )
            );
    }

    // removes accidental duplications from the seats in a round
    // mustn't sit a user in the seats in a specific round
    const checkAndRemoveDuplicationsInSeatOrderRaw = (seatOrderRaw) => {
        [0, 1, 2].forEach((round) => {
            const alreadyExistingSeatUsers = [];
            seatOrderRaw.seats
                .filter((s) => s.round === round && s.user)
                .forEach((s) => {
                    if (alreadyExistingSeatUsers.includes(s.user)) {
                        s.user = null;
                    } else {
                        alreadyExistingSeatUsers.push(s.user);
                    }
                });
        });
    };

    // if some data changed in the background updating and removing those participants from the seats
    const removeParticipantsFromSeatOrderRaw = (
        seatOrderRaw,
        allParticipants
    ) => {
        if (!seatOrderRaw?.seats || !allParticipants) {
            return;
        }

        seatOrderRaw.seats
            .filter((s) => !allParticipants.map((p) => p.id).includes(s.user))
            .forEach((s) => {
                s.user = null;
            });

        return seatOrderRaw;
    };

    // parseSeatOrder() creates and sets the SeatOrder model which will represents the whole seatOrder model for the UI
    // params: seatOrderRaw stringified model and the participants list are the parameters
    function parseSeatOrder(seatOrderRaw, participants) {
        console.log("Participat", participants);
        if (!participants || !seatOrderRaw || participants.length === 0) {
            return;
        }

        const arrayOfSeats = Array.from(seatOrderRaw.seats);

        const roundsRawFirst = arrayOfSeats.filter((seat) => seat.round === 0);
        const roundsRawSecond = arrayOfSeats.filter((seat) => seat.round === 1);
        const roundsRawThird = arrayOfSeats.filter((seat) => seat.round === 2);

        const roundsRawFirstTablesIndeces = [
            ...new Set(roundsRawFirst.map((seat) => seat.table)),
        ];
        const roundsRawSecondTablesIndeces = [
            ...new Set(roundsRawSecond.map((seat) => seat.table)),
        ];
        const roundsRawThirdTablesIndeces = [
            ...new Set(roundsRawThird.map((seat) => seat.table)),
        ];

        const roundsRawFirstTables = roundsRawFirstTablesIndeces.map((index) =>
            roundsRawFirst.filter((seat) => seat.table === index)
        );
        const roundsRawSecondTables = roundsRawSecondTablesIndeces.map((index) =>
            roundsRawSecond.filter((seat) => seat.table === index)
        );
        const roundsRawThirdTables = roundsRawThirdTablesIndeces.map((index) =>
            roundsRawThird.filter((seat) => seat.table === index)
        );

        const roundFirst = creatingRound(roundsRawFirstTables);
        const roundSecond = creatingRound(roundsRawSecondTables);
        const roundThird = creatingRound(roundsRawThirdTables);

        const seatOrderObjModel = new SeatOrderModel("", [
            new RoundModel(0, [...roundFirst]),
            new RoundModel(1, [...roundSecond]),
            new RoundModel(2, [...roundThird]),
        ]);

        setSeatOrder({ ...seatOrderObjModel });
    }

    const creatingRound = (roundRawTables) => {
        return roundRawTables.map(
            (table) =>
                new TableModel(table[0].table, 0, [
                    table.map(
                        (seat) => new SeatModel(seat.seat, 0, table[0].table, seat.user)
                    ),
                ])
        );
    };

    // updating the seatOrderRaw stringified version in the state in order to track the changes
    const updateSeatOrdersRaw = (seatOrderRaw) => {
        if (!seatOrderRaw || !allDisplayedParticipants || allDisplayedParticipants.length === 0) {
            return;
        }

        setSeatOrderRaw({ ...seatOrderRaw });
        parseSeatOrder(seatOrderRaw, allDisplayedParticipants);

        updateUnseatedStatusOfParticipants(
            allDisplayedParticipants,
            selectedRound,
            seatOrderRaw
        );
    };

    const fetchSeatOrders = () => {
        fetchSeatOrdersRaw()
            .then((res) => res.json())
            .then((res) => {
                console.log("Resp", res);
                if (!res.seat_order) {
                    console.log("fetchSeatOrders !res.seat_order: ", res);
                    res.seat_order = {};
                    res.seat_order.seats = [];
                    res.seat_order.seatOrder = null;
                }
                if (
                    (res.seat_order && !isNaN(res.seat_order.id)) ||
                    (res.seat_order && res.seat_order.id === null)
                ) {
                    console.log("fetchSeatOrders res.seat_order: ", res);
                    res.seat_order.seatOrder = res.seat_order.id;
                    delete res.seat_order.id;
                }
                res.seat_order = removeParticipantsFromSeatOrderRaw(
                    res.seat_order,
                    allDisplayedParticipants
                );
                setSeatStarted(res.seat_order.seatOrder !== null);
                checkAndRemoveDuplicationsInSeatOrderRaw(res.seat_order);
                updateSeatOrdersRaw(res.seat_order);
                updateMeetingCriteria(res);
                console.log("fetchSeatOrders: ", res);
                handleParticipants(allDisplayedParticipants, res.seat_order);
            });
    };

    const handleConflictsBetweenParticipants = (participants) => {
        if (!participants || participants.length === 0) {
            return;
        }

        const actuallyConflictingIds = new Set(
            participants
                .map((p) => +p.company?.reservationConflictId)
                .filter(
                    (id, index) =>
                        !isNaN(id) &&
                        participants
                            .map((p) => +p.company?.reservationConflictId)
                            .indexOf(id) !== index
                )
        );

        return [...actuallyConflictingIds];
    };

    const getBackgroundColor = (index) => {
        return colors[index % colors.length];
    };

    const updateUnseatedStatusOfParticipants = (
        participants,
        round,
        seatOrderRaw
    ) => {
        if (!participants || participants.length === 0) {
            return;
        }

        seatOrderRaw.seats
            .filter((s) => s.round === round && s.user)
            .map((s) => s.user)
            .map((id) => participants.find((p) => p.id === id))
            .forEach((p) => {
                if (p) {
                    p.unseated[selectedRound] = false;
                }
            });
    };

    const generateOthersRounds = () => {
        const seatsForFirstRound = seatOrderRaw.seats.filter(
            (seat) => seat.round === 0
        );
        const maxTableIndex = Math.max(...seatsForFirstRound.map((s) => s.table));

        const seatsForSecondRound = [];
        const seatsForThirdRound = [];

        seatsForFirstRound.forEach((s) => {
            seatsForSecondRound.push({
                user: s.user,
                round: 1,
                seat: s.seat,
                table: (s.table + s.seat) % (maxTableIndex + 1),
            });
        });
        seatsForSecondRound.forEach((s) => {
            seatsForThirdRound.push({
                user: s.user,
                round: 2,
                seat: s.seat,
                table: (s.table + s.seat) % (maxTableIndex + 1),
            });
        });

        seatOrderRaw.seats = [
            ...seatsForFirstRound,
            ...seatsForSecondRound,
            ...seatsForThirdRound,
        ];

        updateSeatOrdersRaw(seatOrderRaw);
        newChanges.current = true;
        setIsAnyChange(true);
        setIsGeneratedRoundsDialogueOpen(true);
    };

    const handleTablesChange = (difference, fromModal = false) => {
        if (difference > 0) {
            const seatsSeperatedByRound = seatOrderRaw.seats.filter(
                (s) => s.round === selectedRound
            );

            const maxTableIndex =
                Math.max(...seatsSeperatedByRound.map((s) => s.table)) >= 0
                    ? Math.max(...seatsSeperatedByRound.map((s) => s.table))
                    : -1;

            const allIdsForSeats =
                [...new Set(seatsSeperatedByRound.map((s) => s.seat))].length > 0
                    ? [...new Set(seatsSeperatedByRound.map((s) => s.seat))]
                    : [0];

            const newTableSeats = [];
            allIdsForSeats.forEach((id) => {
                newTableSeats.push({
                    round: selectedRound,
                    seat: id,
                    table: maxTableIndex + 1,
                    user: null,
                });
            });

            seatOrderRaw.seats.push(...newTableSeats);
        }
        if (difference < 0) {
            const seatsSeperatedByRound = seatOrderRaw.seats.filter(
                (s) => s.round === selectedRound
            );
            const maxTableIndex = Math.max(
                ...seatsSeperatedByRound.map((s) => s.table)
            );
            const seatsToBeDeletedWithParticipant = seatOrderRaw.seats.filter(
                (s) =>
                    s.table === maxTableIndex &&
                    s.user !== null &&
                    s.round === selectedRound
            );

            if (seatsToBeDeletedWithParticipant.length > 0 && !fromModal) {
                setNotificationForDeletingTableAlert(true);
                return;
            }

            seatsToBeDeletedWithParticipant.forEach((p) => {
                removeParticipantFromTable(
                    allDisplayedParticipants.filter((pa) => pa.id === p.user)[0]
                );
            });

            seatOrderRaw.seats = seatOrderRaw.seats.filter(
                (s) => s.table !== maxTableIndex || s.round !== selectedRound
            );
        }

        updateSeatOrdersRaw(seatOrderRaw);
        newChanges.current = true;
        setIsAnyChange(true);
    };

    const handleSeatsSameTotal = (difference, fromModal = false) => {
        if (difference > 0) {
            const seatsSepratedByRound = seatOrderRaw.seats.filter(
                (s) => s.round === selectedRound
            );
            const tablesIndeces = [
                ...new Set(seatsSepratedByRound.map((s) => s.table)),
            ];
            // adding one seat
            tablesIndeces.forEach((tableIndex) => {
                const seatIdLast = Math.max(...seatsSepratedByRound.map((s) => s.seat));
                seatOrderRaw.seats.push({
                    round: selectedRound,
                    seat: seatIdLast + 1,
                    table: tableIndex,
                    user: null,
                });
            });
        }
        if (difference < 0) {
            const seatsSepratedByRound = seatOrderRaw.seats.filter(
                (s) => s.round === selectedRound
            );
            const seatIdLast = Math.max(...seatsSepratedByRound.map((s) => s.seat));
            const seatsToBeDeletedWithParticipant = seatOrderRaw.seats.filter(
                (s) =>
                    s.seat === seatIdLast && s.user !== null && s.round === selectedRound
            );

            if (seatsToBeDeletedWithParticipant.length > 0 && !fromModal) {
                setNotificationForDeletingSeatAlert(true);
                return;
            }

            seatsToBeDeletedWithParticipant.forEach((p) =>
                removeParticipantFromTable(
                    allDisplayedParticipants.filter((pa) => pa.id === p.user)[0]
                )
            );

            seatOrderRaw.seats = seatOrderRaw.seats.filter(
                (s) => s.seat !== seatIdLast || s.round !== selectedRound
            );
        }
        updateSeatOrdersRaw(seatOrderRaw);
        newChanges.current = true;
        setIsAnyChange(true);
    };

    const handleSelectedParticipant = (seat) => {
        if (!selectedParticipant) {
            return;
        }

        const alreadySeatedUser = seatOrderRaw.seats.filter(
            (s) => s.round === selectedRound && selectedParticipant.id === s.user
        )[0];

        if (alreadySeatedUser) {
            alreadySeatedUser.user = null;
        }

        const participants = allDisplayedParticipants;
        const current = participants.filter(
            (participant) => participant.id === selectedParticipant.id
        )?.[0];
        if (current) current.unseated[selectedRound] = false;

        const selectedSeatLocation = seatOrderRaw.seats.filter(
            (s) =>
                s.round === selectedRound &&
                s.table === seat.table &&
                s.seat === seat.id
        )[0];
        selectedSeatLocation.user = selectedParticipant.id;

        handleParticipants(participants, seatOrderRaw);
        updateSeatOrdersRaw(seatOrderRaw);
        setSelectedParticipant(null);
        setIsAnyChange(true);
        newChanges.current = true;
    };

    const removeFromTableSelectedParticipant = (seatRaw) => {
        if (!seatRaw) {
            return;
        }

        const toBeRemoved = seatOrderRaw.seats.find(
            (s) =>
                s.seat === seatRaw.id &&
                s.round === selectedRound &&
                s.table === seatRaw.table
        );

        allDisplayedParticipants.find((p) => p.id === toBeRemoved.user).unseated[
            selectedRound
            ] = true;
        toBeRemoved.user = null;

        handleParticipants(allDisplayedParticipants, seatOrderRaw);
        updateSeatOrdersRaw(seatOrderRaw);
        // setSelectedParticipant(null);
        setIsAnyChange(true);
        newChanges.current = true;
    };

    const removeParticipantFromTable = (seat) => {
        if (!seat) {
            return;
        }

        seat.unseated[selectedRound] = true;
        seatOrderRaw.seats.find((s) => s.user === seat.id).user = null;

        handleParticipants(allDisplayedParticipants, seatOrderRaw);
        setSelectedParticipant(null);
        setIsAnyChange(true);
        newChanges.current = true;
    };

    const restoreSeatOrderBackup = (contentObj) => {
        updateSeatOrdersRaw(contentObj);
        setSelectedParticipant(null);
        setIsAnyChange(true);
        newChanges.current = true;
    }

    usePrompt(isAnyChange, setIsNotSavedDialogueOpen);

    useEffect(() => {
        getParticipants();

        const interval = setInterval(() => {
            manageComparingParticipantsChangesInBackground();
        }, 15000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!allDisplayedParticipants) {
            return;
        }

        if (!seatOrderRaw) {
            fetchSeatOrders();
        }

        updateSeatOrdersRaw(seatOrderRaw);
    }, [allDisplayedParticipants]);

    useEffect(() => {
        handleParticipants(allDisplayedParticipants, seatOrderRaw);
        if (seatOrderRaw && allDisplayedParticipants) {
            removeParticipantsFromSeatOrderRaw(seatOrderRaw, allDisplayedParticipants);
        }
    }, [seatOrderRaw]);

    useEffect(() => {
        handleParticipants(allDisplayedParticipants, seatOrderRaw);
    }, [selectedRound]);

    useEffect(() => {
        compareChangesFromBackground(
            // allParticipants,
            // newlyAllParticipants,
            registeredParticipants,
            newlyRegisteredParticipants,
            appearedParticipants,
            newlyAppearedParticipants,
            cancelled,
            newlyCancelled
        );
    }, [newlyRegisteredParticipants, newlyAppearedParticipants, newlyCancelled]);

    useEffect(() => {
        updateSeatOrdersRaw(seatOrderRaw);
    }, [isRefreshParticipantsListDialogueOpen]);

    console.log({ seatOrder, selectedRound });
    return seatOrder && !isNaN(selectedRound) ? (
        <div className={`${styles.seatOrderContainer}`}>
            <div className={styles.upperRowDiv}>
                <UpperRowContainer
                    selectedRound={selectedRound}
                    setSelectedRound={setSelectedRound}
                    generateOthersRounds={generateOthersRounds}
                    uploadChanges={uploadChanges}
                    apiCallOngoing={seatOrderSaveOngoing}
                    pushNotifications={updatePushNotifications}
                    refreshParticipantList={refreshParticipantList}
                    newChanges={isChangesFromBackground}
                    notSavedChanges={isAnyChange}
                    seatStarted={seatStarted}
                    generated={seatOrderRaw.seats.find(s => s.round === 1) && seatOrderRaw.seats.find(s => s.round === 2)}
                    meetingCriteria={meetingCriteria}
                    meetingCriteriaChanged={() => {
                        fetchSeatOrders();
                        getParticipants();
                    }}
                    isLocalBackupChecked={isLocalBackupChecked}
                    setIsLocalBackupChecked={setIsLocalBackupChecked}
                    setIsSeatOrderBackupModalOpen={setIsSeatOrderBackupModalOpen}
                />
            </div>
            <div className={styles.mainRowDiv}>
                <div className={styles.leftPanelDiv}>
                    <ParticipantsPoolTable
                        participants={participantsInPool}
                        selectedParticipant={selectedParticipant}
                        handleParticipantSelection={handleParticipantSelection}
                    />
                </div>
                <div className={styles.rightPanelDiv}>
                    {
                        seatStarted ? (<Fragment>
                            <div className={`${styles.roundContainer}`}>
                                {!isNaN(selectedRound) &&
                                    [0, 1, 2].map((round) => (
                                        <div
                                            key={round}
                                            onClick={() => {
                                                if (seatOrderRaw.seats.find(s => s.round === 1) && seatOrderRaw.seats.find(s => s.round === 2)) setSelectedRound(round);
                                            }}
                                            className={`${styles.roundButton} ${
                                                round === selectedRound ? styles.roundTabSelected : ""
                                            } ${!seatOrderRaw.seats.find(s => s.round === 1) && !seatOrderRaw.seats.find(s => s.round === 2) && round > 0 && styles.disabledButton}`}
                                        >
                                            {round + 1}. kör
                                        </div>
                                    ))}
                            </div>
                            <TablesContainer
                                seatOrder={seatOrder}
                                setSeatOrder={setSeatOrder}
                                selectedRound={selectedRound}
                                handleTablesChange={handleTablesChange}
                                handleSeatsSameTotal={handleSeatsSameTotal}
                                // getParticpantDataForTableSeat={getParticpantDataForTableSeat}
                                allParticipants={allDisplayedParticipants}
                                selectedParticipant={selectedParticipant}
                                handleParticipantSelection={handleParticipantSelection}
                                handleSelectedParticipant={handleSelectedParticipant}
                                removeFromTableSelectedParticipant={
                                    removeFromTableSelectedParticipant
                                }
                            />
                        </Fragment>) : (
                            <div style={{ flex: 1 }}>
                                <div style={{
                                    textAlign: "center",
                                    margin: 0,
                                    position: "absolute",
                                    top: "50%",
                                    transform: 'translateY(-50%) translateX(50%)',
                                    left: "50%"
                                }}>
                                    <div>Az ültetés még nem lett elkezdve!</div>
                                    <button style={{
                                        margin: 20,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        minHeight: 60,
                                        fontWeight: 500,
                                        fontSize: 20,
                                        color: "white",
                                        borderRadius: 8,
                                        background: 'rgb(51, 74, 115)',
                                    }}
                                            onClick={() => {
                                                for (let table = 0; table < 3; table++)
                                                    for (let seat = 0; seat < 4; seat++)
                                                        seatOrderRaw.seats.push({
                                                            round: 0,
                                                            table: table,
                                                            seat: seat,
                                                            user: null,
                                                        });
                                                updateSeatOrdersRaw(seatOrderRaw);
                                                newChanges.current = true;
                                                setIsAnyChange(true);
                                                setSeatStarted(true);
                                            }}>Kezdés
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

            <Modal
                isOpen={isNotSavedDialogueOpen}
                onClose={() => {
                }}
                title={"Mentetlen adatok"}
                body={"Mentetlen adatok elveszhetnek, ha nem menti őket"}
                onCloseTitle={"Rendben"}
            >
                <button
                    onClick={() => {
                        setIsAnyChange(false);
                        setIsNotSavedDialogueOpen(false);
                    }}
                    className={styles.buttonOnModal}
                >
                    Mentés nélkül tovább
                </button>
                <button
                    onClick={() => {
                        setIsNotSavedDialogueOpen(false);
                    }}
                    className={styles.buttonOnModal}
                >
                    Maradok az oldalon
                </button>
            </Modal>

            <Modal
                isOpen={notificationForDeletingTableAlert}
                onClose={() => {
                    setNotificationForDeletingTableAlert(false);
                }}
                title={"Megerősítés"}
                body={"Az asztal kitörlésével kitöröl már leültetett résztvevőket"}
                onCloseTitle={"Rendben"}
            >
                <button
                    onClick={() => {
                        setNotificationForDeletingTableAlert(false);
                        handleTablesChange(-1, true);
                    }}
                    className={`${styles.buttonOnModal} ${styles.buttonDelete}`}
                >
                    Törlés
                </button>
                <button
                    onClick={() => {
                        setNotificationForDeletingTableAlert(false);
                    }}
                    className={styles.buttonOnModal}
                >
                    Mégsem
                </button>
            </Modal>

            <Modal
                isOpen={notificationForDeletingSeatAlert}
                onClose={() => {
                    setNotificationForDeletingSeatAlert(false);
                }}
                title={"Megerősítés"}
                body={"A sorok törlésével kitöröl már leültetett résztvevőket"}
                onCloseTitle={"Rendben"}
            >
                <button
                    onClick={() => {
                        setNotificationForDeletingSeatAlert(false);
                        handleSeatsSameTotal(-1, true);
                    }}
                    className={`${styles.buttonOnModal} ${styles.buttonDelete}`}
                >
                    Törlés
                </button>
                <button
                    onClick={() => {
                        setNotificationForDeletingSeatAlert(false);
                    }}
                    className={styles.buttonOnModal}
                >
                    Mégsem
                </button>
            </Modal>

            <Modal
                isOpen={isSaveDialogueOpen}
                onClose={() => {
                    setIsSaveDialogueOpen(false);
                }}
                title={"Mentés"}
                body={isSaveSuccessful}
                isError={isSaveSuccessful.startsWith("Nem sikerült a mentés")}
                isSuccess={isSaveSuccessful.startsWith(
                    "A mentés sikeresen befejeződött"
                )}
                onCloseTitle={"Rendben"}
            ></Modal>

            <Modal
                isOpen={isPushNotiDialogueOpen}
                onClose={() => {
                    setIsPushNotiDialogueOpen(false);
                }}
                title={"Értesítések kiküldése"}
                body={"Az értesítések kiküldése sikeresen megtörtént"}
                onCloseTitle={"Rendben"}
            ></Modal>

            <Modal
                isOpen={isGeneratedRoundsDialogueOpen}
                onClose={() => {
                    setIsGeneratedRoundsDialogueOpen(false);
                }}
                title={"Generálás"}
                body={"A második és harmadik kör kigenerálása megtörtént"}
                onCloseTitle={"Rendben"}
            ></Modal>

            <Modal
                isOpen={isRefreshParticipantsListDialogueOpen}
                onClose={() => {
                    setIsRefreshParticipantsListDialogueOpen(false);
                }}
                title={"Frissítés"}
                body={"A résztvevők listájának frissítése megtörtént"}
                onCloseTitle={"Rendben"}
            ></Modal>

            <Modal
                isOpen={isSeatOrderBackupModalOpen}
                title={"Visszatöltés"}
                body={"Válasszon egy biztonsági mentést!"}
                children={(
                    <div>
                        <div style={{ overflowY: 'scroll', maxHeight: 140, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {isSeatOrderBackupModalOpen &&
                                SeatOrderBackupStorage
                                    .list(localStorage.getItem("event"))
                                    ?.sort((a, b) => a.key.localeCompare(b.key))
                                    ?.reverse()
                                    ?.map((item) => (
                                        <button key={item.key}
                                            className={`${styles.buttonOnModal}`}
                                            onClick={() => {
                                                restoreSeatOrderBackup(item.contentObj)
                                                setIsSeatOrderBackupModalOpen(false);
                                            }}>
                                            {new Date(item.keySegments[2]).toLocaleString()}
                                        </button>
                                    )) || 
                                    <div
                                      className={`modal-body ${"modal-body-error"}`}
                                    >
                                      Még nincs mentett ültetés.
                                    </div>}
                        </div>
                        <div className="modal-footer">
                            <button
                                className="modal-close-button"
                                onClick={() => {
                                    setIsSeatOrderBackupModalOpen(false);
                                }}
                            >
                                Mégsem
                            </button>
                        </div>
                    </div>
                )}
            ></Modal>
        </div>
    ) : (
        <div>
            Nincs megjeleníthető adat, esetleg nincs még résztvevő, vagy hiba lépett
            fel
        </div>
    );
};
