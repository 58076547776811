import React from 'react';
import App from '../App';
import { QRCodeCanvas } from 'qrcode.react';
import './MobileAppPage.css'

export function MobileAppPage() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  if(/android/i.test(userAgent))
    return window.location.replace('https://play.google.com/store/apps/details?id=hu.greenformatics.mvuk')
  if(/iPhone/.test(userAgent) && !window.MSStream)
    return window.location.replace('https://apps.apple.com/hu/app/mvük-értékes-üzleti-közösség/id6447942222')

  return (
    <div style={{
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    }}>
      <div class="app-page-header">
        <div class="app-page-header-overlay" />
        <div style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: 800, overflow: 'hidden', zIndex: 100, display: 'flex'}}>
          <div style={{width: '100vw'}}>
            <h1 style={{
              fontFamily: 'Sans-serif',
              maxWidth: 800,
              zIndex: 100
            }}>Töltsd le mobilapplikációnkat!</h1>
          </div>

        </div>
      </div>
      <div class="content-container">
        <img loading="lazy" width="800" height="572" src="https://mvuk.hu/wp-content/uploads/2024/04/applikacio-image-1024x732.jpg" class="content-image attachment-large size-large wp-image-19760 lazyloaded" alt="" decoding="async" sizes="(max-width: 800px) 100vw, 800px" srcset="https://mvuk.hu/wp-content/uploads/2024/04/applikacio-image-1024x732.jpg 1024w, https://mvuk.hu/wp-content/uploads/2024/04/applikacio-image-300x214.jpg 300w, https://mvuk.hu/wp-content/uploads/2024/04/applikacio-image-768x549.jpg 768w, https://mvuk.hu/wp-content/uploads/2024/04/applikacio-image.jpg 1236w" data-ll-status="loaded"></img>
        <div style={{
          padding: 10,
          marginTop: 20,
          textAlign: 'justify',
        }}>
          <p>Örülünk, hogy eseményünk felkeltette az érdeklődésedet! <strong>Regisztrációdat az „MVÜK – értékes üzleti közösség” mobilapplikáción keresztül tudod leadni. <br /></strong></p>
          <p>Nincs más dolgod, mint a mobiltelefonod operációs rendszerének megfelelő áruházából letölteni az <strong>„MVÜK – értékes üzleti közösség”&nbsp;</strong>alkalmazást (App Store, Google Play), majd megnyitni és a <strong>regisztráció&nbsp;</strong>gombra kattintva megadni <strong>azt az email címedet, amire ezt a levelet is kaptad</strong>. Ezután kapsz egy ideiglenes jelszót, amit meg kell változtatnod. Amikor ezzel kész vagy, már használhatod is az alkalmazást!</p>
          <p><strong>Néhány tipp a használathoz:&nbsp;</strong></p>
          <ul>
            <li>Engedélyezd az értesítések kézbesítését az applikáció telepítésekor felugró ablakon, vagy később, telefonod beállításaiban, hogy mindig értesülhess az új eseményekről, lehetőségekről!</li>
            <li>A profil oldaladon ne felejtsd el beállítani az érdeklődési köreidet, így mindig naprakész lehetsz a témákba vágó eseményekről!</li>
            <li>Az esemény adatlapján a naptár ikonnal mentsd be azt a naptáradba, hogy tervezhess az adott rendezvénnyel!</li>
            <li>Akkor is jelezz vissza egy-egy esemény kapcsán, ha nem szeretnél azon részt venni!</li>
            <li>A profil oldalon ne felejts el beállítani profilképet, hogy még inkább személyessé tehesd az applikációt!</li>
          </ul>
          <p>További útmutatást és a gyakran ismételt kérdésekre a választ, pedig&nbsp;<a href="https://mvuk.hu/mvuk-ertekes-uzleti-kozosseg-applikacio/" target="_blank" rel="noopener noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://mvuk.hu/mvuk-ertekes-uzleti-kozosseg-applikacio/&amp;source=gmail&amp;ust=1687341465665000&amp;usg=AOvVaw00xzbuxt7oSe87CTHN5YY2"><strong>IDE</strong></a> kattintva találod. Az MVÜK – értékes üzleti közösség mobilapplikációjára vonatkozó biztonsági irányelvekről <a href="https://drive.google.com/file/d/11veDV33Qlo8xIulFAyO5Kr0lKySzl4mG/view?usp=sharing" target="_blank" rel="noopener"><strong>ITT</strong> </a>tudsz tájékozódni.</p>
          <p>Amennyiben elakadnál vagy kérdésed van keress minket bizalommal a&nbsp;<a href="mailto:regisztracio@mvuk.hu" target="_blank" rel="noopener">regisztracio@mvuk.hu</a>&nbsp;email címen, vagy az alábbi telefonszámon.</p>
          <ul>
            <li>Glücker Roland, üzlettámogató, MVÜK Zrt. –&nbsp; +36704278859</li>
          </ul>
          <p>&nbsp;</p>
          <p>Szívesen vesszük észrevételeidet, javaslataidat is, amelyeket szintén a fenti email címre tudod elküldeni.</p>
          <p>Jó böngészést kívánunk!</p>
          <p>&nbsp;</p>
          <p><a href="https://apps.apple.com/hu/app/mv%C3%BCk-%C3%A9rt%C3%A9kes-%C3%BCzleti-k%C3%B6z%C3%B6ss%C3%A9g/id6447942222?l=hu" target="_blank" rel="noopener"><img decoding="async" class="alignnone wp-image-19755 size-medium lazyloaded" src="https://mvuk.hu/wp-content/uploads/2024/04/app-300x89.jpg" alt="" width="300" height="89" sizes="(max-width: 300px) 100vw, 300px" srcset="https://mvuk.hu/wp-content/uploads/2024/04/app-300x89.jpg 300w, https://mvuk.hu/wp-content/uploads/2024/04/app.jpg 413w" data-ll-status="loaded" /><noscript><img decoding="async" class="alignnone wp-image-19755 size-medium" src="https://mvuk.hu/wp-content/uploads/2024/04/app-300x89.jpg" alt="" width="300" height="89" srcset="https://mvuk.hu/wp-content/uploads/2024/04/app-300x89.jpg 300w, https://mvuk.hu/wp-content/uploads/2024/04/app.jpg 413w" sizes="(max-width: 300px) 100vw, 300px" /></noscript></a><a href="https://play.google.com/store/apps/details?id=hu.greenformatics.mvuk&amp;fbclid=IwAR2H0SgeX0y1gv57oWrSu8Xh4cj_DTLnbZcKkb8McZVs8LyzgIj3-a7fRa4&amp;pli=1" target="_blank" rel="noopener"><img decoding="async" class="alignnone wp-image-19756 size-medium lazyloaded" src="https://mvuk.hu/wp-content/uploads/2024/04/play-300x89.jpg" alt="" width="300" height="89" sizes="(max-width: 300px) 100vw, 300px" srcset="https://mvuk.hu/wp-content/uploads/2024/04/play-300x89.jpg 300w, https://mvuk.hu/wp-content/uploads/2024/04/play.jpg 413w" data-ll-status="loaded" /><noscript><img decoding="async" class="alignnone wp-image-19756 size-medium" src="https://mvuk.hu/wp-content/uploads/2024/04/play-300x89.jpg" alt="" width="300" height="89" srcset="https://mvuk.hu/wp-content/uploads/2024/04/play-300x89.jpg 300w, https://mvuk.hu/wp-content/uploads/2024/04/play.jpg 413w" sizes="(max-width: 300px) 100vw, 300px" /></noscript></a></p>
        </div>
      </div>
    </div>
  );
};
