import React, { useEffect, useState, useCallback } from "react"
import { Consts } from "../Consts"

const LIMITS = [25, 100, 500, 1000, 99999999]

export function Messages() {
  //const { eventContext } = useOutletContext()
  const eventid = localStorage.getItem('event')
  const [messages, setMessages] = useState(null)
  const [selectedLimit, setSelectedLimit] = useState(LIMITS[0])
  const [loading, setLoading] = useState(false)
  const [eventFilter, setEventFilter] = useState(!!eventid)


  const fetchMessages = useCallback(async (toXls) => {
    let url = `${Consts.baseUrl}api/message/export/${toXls ? 'xls' : 'ui'}?limit=${selectedLimit}`
    if (!!eventid && eventFilter) url += `&eventid=${eventid}`
    const respone = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    })
    return await respone.json()
  }, [eventid, eventFilter, selectedLimit])

  const queryMessagesToUi = useCallback(async () => {
    const messages = await fetchMessages(false)
    setMessages(messages)
  }, [fetchMessages])

  useEffect(() => {
    queryMessagesToUi()
  }, [queryMessagesToUi])

  if (!messages)
    return (
      <div
        className="spinner-border"
        role="status"
        style={{
          "--bs-spinner-width": "5rem",
          "--bs-spinner-height": "5rem",
          "--bs-spinner-border-width": "0.5em",
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    )

  async function exportMessagesToXls() {
    setLoading(true)
    const xls = await fetchMessages(true)
    const anchor = document.createElement('a')
    anchor.href = `${Consts.baseUrl}${xls.path}`
    anchor.download = xls.path.split('/').slice(-1)
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    setLoading(false)
  }

  return (
    <div className="col">
      <div className="eventbottomwrapper">
        <h2>ÜZENETEK</h2>
        <div className="end-aligned-text">
          {
            !!eventid && (
              <>
                <input
                  id='eventFilter'
                  type="checkbox"
                  checked={eventFilter}
                  onChange={e => setEventFilter(e.target.checked)} />
                <label
                  htmlFor="eventFilter"
                  style={{ marginLeft: 8, marginRight: 16 }}>eseményre szűrés</label>
              </>
            )
          }
          <select
            value={selectedLimit}
            style={{ marginRight: 16 }}
            onChange={e => setSelectedLimit(e.target.value)}
          >{
              LIMITS.map((limit, index) => {
                const isLast = index === LIMITS.length - 1
                return (
                  <option key={index} value={limit}>{isLast ? 'összes' : `${limit} db`}</option>
                )
              })
            }
          </select>
          <input
            className={'button ' + (loading && 'button-disabled')}
            type="button"
            value={loading ? 'Exportálás...' : 'Exportálás'}
            onClick={exportMessagesToXls}
            disabled={loading}
          />
        </div>
        {messages.length === 0 && <div>Nincs még üzenet.</div>}
        {messages.length > 0 &&
          <table className="onsite mtop25 table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col">Dátum</th>
                <th scope="col">Név</th>
                <th scope="col">Email</th>
                <th scope="col">Esemény</th>
                <th scope="col">Szöveg</th>
              </tr>
            </thead>
            <tbody>
              {
                messages.map((m, index) => {
                  return (
                    <tr key={index}>
                      <td>{new Date(m.createdAt).toLocaleString()}</td>
                      <td>{`${m.user?.lastName ?? ''} ${m.user?.firstName ?? ''}`}</td>
                      <td>{m.user?.email ?? '-'}</td>
                      <td>{m.event?.title ?? '-'}</td>
                      <td>{m.body}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>}
      </div>
    </div>
  )
}
