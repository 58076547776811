import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { TableSeat } from "./TableSeat";

export const Table = (props) => {
    const [isCompanyConflict, setIsCompanyConflict] = useState(false);
    const [isMeetingConflict, setIsMeetingConflict] = useState(false);
    const [conflictingTags, setConflictingTags] = useState([]);

    const handleShowConflictsOnTable = (seats) => {
        const conflicts = props.allParticipants
            .filter(p => seats
                .map(s => s.user)
                .includes(p.id))
            .map(x => x.conflict)
            .filter(c => c);

        return conflicts.length !== [...new Set(conflicts)].length;
    };

    const handleMeetingConflictsOnTable = (seats) => {
        const tags = getTags(seats);

        return tags.length !== [...new Set(tags)].length;
    };

    const getConflictingTags = (seats) => {
        const tags = getTags(seats);

        return tags.filter((item, index) => tags.indexOf(item) !== index);
    };

    const getTags = (seats) => {
        return props.allParticipants
            .filter(p => seats
                .map(s => s.user)
                .includes(p.id))
            .map(x => x.tags)
            .filter(c => c)
            .flat();
    };

    useEffect(() => {
        setIsCompanyConflict(handleShowConflictsOnTable(...props.table.seats));
    }, [props.selectedParticipant, props.table]);

    useEffect(() => {
        setIsMeetingConflict(handleMeetingConflictsOnTable(...props.table.seats));
    }, [props.selectedParticipant, props.table]);

    useEffect(() => {
        setConflictingTags(getConflictingTags(...props.table.seats));
    }, [props.selectedParticipant, props.table]);

    return (
        <div className={`${styles.table} ${(isCompanyConflict || isMeetingConflict) && styles.tableConflict}`}>
            <div className={`${styles.tableName}`}>{props.index}</div>
            {props.table.seats &&
                props.table.seats[0].map((tableSeat, index) => (
                    <TableSeat
                        isSelected={false}
                        key={index}
                        index={`${props.index}${index}`}
                        selectedParticipant={props.selectedParticipant}
                        handleSelectedParticipant={props.handleSelectedParticipant}
                        handleParticipantSelection={props.handleParticipantSelection}
                        selectedRound={props.selectedRound}
                        allParticipants={props.allParticipants}
                        removeFromTableSelectedParticipant={props.removeFromTableSelectedParticipant}
                        onRemove={props.onRemove}
                        tableSeat={tableSeat}
                        conflictingTags={conflictingTags}
                    />
                ))}
        </div>
    );
};
