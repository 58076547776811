export class Participant {
  constructor(
    id,
    username,
    email,
    firstName,
    lastName,
    company,
    clubs,
    interests,
    pictureOriginal,
    pictureThumbmail,
    needChangePassword,
    typeOfAppearence,
    fcmTokens,
    tags,
    unseated = [true, true, true],
  ) {
    this.id = id;
    this.username = username;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.company = company;
    this.clubs = clubs;
    this.interests = interests;
    this.pictureOriginal = pictureOriginal;
    this.pictureThumbmail = pictureThumbmail;
    this.needChangePassword = needChangePassword;
    this.typeOfAppearence = typeOfAppearence;
    this.fcmTokens = fcmTokens;
    this.unseated = unseated;
    this.tags = tags;
  }

  // setUnseated(isUnseated = false) {
  //   this.unseated = isUnseated;
  // }
}
