//import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";

export const UpperBackupRowContainer = (props) => {
    const apiCallOngoing = props.apiCallOngoing
    //const [apiCallOngoing, setApiCallOngoing] = useState(props.apiCallOngoing);

    const handleCheckboxChange = () => {
        props.setIsLocalBackupChecked(!props.isLocalBackupChecked);
    };

    return (
        <div className={styles.upperRowContainer} style={{marginTop: 2}}>
            <div className={styles.roundTabsContainer}>
                <div style={{ flex: 1 }} ></div>
                <div className={`${styles.roundTab} ${styles.roundTabSmall} ${(!props.seatStarted || apiCallOngoing) && styles.disabledButton}`}>
                    <button style={{ flex: 1, height: '100%' }} onClick={() => {
                        props?.setIsSeatOrderBackupModalOpen?.(true);
                    }} disabled={apiCallOngoing}>Visszatöltés
                    </button>
                </div>
                <div className={`${styles.roundTab} ${styles.roundTabSmall} ${(!props.seatStarted || apiCallOngoing) && styles.disabledButton}`}>
                    <button style={{ flex: 1, height: '100%' }} onClick={handleCheckboxChange} disabled={apiCallOngoing}>
                        <input
                            disabled={!props.seatStarted || apiCallOngoing}
                            type="checkbox"
                            checked={props.isLocalBackupChecked}
                            style={{ marginRight: 5 }}
                        />
                        Backup
                    </button>
                    {/* <button style={{ flex: 1, height: '100%' }} onClick={() => {
                        if (props.seatStarted) props?.uploadChanges?.();
                    }} disabled={apiCallOngoing}>Mentés
                    </button> */}
                </div>
                <div style={{ flex: 1 }} ></div>
                <div style={{ flex: 1 }} ></div>
                <div style={{ flex: 1 }} ></div>
            </div>
        </div>
    );
};
