import CloseIcon from "../../assets/icons/close-icon.png";
import React from "react";
import styles from "./styles.module.css";
import { categories } from "../../configs/categories";
import { clubs } from "../../configs/clubs";

export const TableCard = (props) => {

    const maxTags = 3;

    const setIndicator = (color, text, tooltip = "", textColor = "") => {
        return { color, text, tooltip, textColor };
    };

    const handleLongText = () => {
        const index = 15;
        const text = props.card.lastName + " " + props.card.firstName;

        return text.length > index ? `${text.substring(0, index)}...` : text;
    };

    const getTags = () => {
        return props.card.tags?.filter(tag => props.conflictingTags?.includes(tag));
    };

    const getVisibleTags = () => {
        const tags = getTags();

        return tags?.length > maxTags ? tags.slice(0, maxTags) : tags;
    };

    const lowerRowIndicators = () => {
        const indicators = [];
        const colorOFNone = "lightgray";
        const preTestForClubs = () => {
            return !!props.card && !!props.card.clubs;
        };

        const preTestForCategories = () => {
            return !!props.card.company && !!props.card.company.income_category;
        };

        clubs.forEach((indicator) => {
            indicators.push(
                preTestForClubs() &&
                props.card.clubs.map((club) => club.name).includes(indicator.name)
                    ? setIndicator(
                        indicator.color,
                        indicator.text,
                        indicator.tooltip,
                        indicator.textColor
                    )
                    : setIndicator(colorOFNone, "")
            );
        });

        categories.forEach((indicator) => {
            indicators.push(
                preTestForCategories() &&
                props.card.company.income_category.minimalIncome === indicator.name
                    ? setIndicator(indicator.color, indicator.text, indicator.tooltip)
                    : setIndicator(colorOFNone, "")
            );
        });

        indicators.push(setIndicator("gray"));

        return indicators;
    };

    const handleBackgroundColour = () => {
        return `linear-gradient(
            to right,
            ${
            props.card.typeOfAppearence === "Appeared"
                ? "lightgreen"
                : "lightgray"
        } 0%,
            ${
            props.card.typeOfAppearence === "Appeared"
                ? "lightgreen"
                : "lightgray"
        } 5%,
            ${props.card.conflict ? props.card.conflict : "white"} 5%,
            ${props.card.conflict ? props.card.conflict : "white"} 100%
          )`;
    };

    return (
        props.card && (
            <div
                className={`${styles.tableCard} ${
                    props.isSelected && styles.isCardSelected
                }`}
                style={{
                    background: handleBackgroundColour(),
                }}
                onClick={() => {
                    props.handleParticipantSelection &&
                    props.handleParticipantSelection(props.card);
                }}
            >
                <div className={styles.cardUpperRow}>
                    <div className={styles.cardLeftColumn}>
                        <div className={styles.seatIdDiv}>{props.index}</div>
                    </div>
                    <div className={styles.cardCenterColumn}>
                        <div className={styles.cardTitle}>
                            {handleLongText()}
                        </div>
                        {getVisibleTags() && <div className={styles.cardTagWrapper}>
                            {(
                                getVisibleTags().map(tag =>
                                    <div className={`${styles.cardTag}`}>
                                        <span>{tag}</span>
                                    </div>
                                )
                            )}
                            {getTags().length > maxTags && (
                                <div className={styles.cardTag}>+</div>
                            )}
                        </div>
                        }

                        {props.isVisible && (
                            <div className={styles.removeIconDiv} onClick={props.onRemove}>
                                <img src={CloseIcon} alt="törlés"/>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.cardLowerRow}>
                    {lowerRowIndicators().length !== 0 &&
                        lowerRowIndicators().map((indicator) => (
                            <span
                                className={`${styles.indicator} ${styles.hoverText}`}
                                style={{
                                    backgroundColor: `${indicator.color}`,
                                    color: `${indicator.textColor}`,
                                }}
                            >
                {indicator.text}
                                {indicator.tooltip && (
                                    <span className={styles.tooltipText}>
                    {indicator.tooltip}
                  </span>
                                )}
              </span>
                        ))}
                </div>
            </div>
        )
    );
};
