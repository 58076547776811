export function localizeState(state) {
  return stateNameAndValues.get(state) ?? ''
}

export const stateNameAndValues = new Map(
  [
    ['Registered', 'Regisztrált'],
    ['RegisteredOnline', 'Biztos jön - online'],
    ['Canceled', 'Lemondta'],
    ['Appeared', 'Megjelent'],
    ['Waiting', 'Várólistán'],
    ['NotAppeared', 'Nem jelent meg'],
    ['WontAttend', 'Biztos nem jön'],
    ['Interested', 'Új érdeklődés'],
    ['Applied', 'Érdeklődő'],
    ['Denied', 'Elutasított'],
    ['NotResponded', 'Vissza nem jelzett'],
  ]
)