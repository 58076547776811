import React, { useEffect, useState } from "react";

const Modal = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    isOpen && (
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">{props.title}</div>
          </div>
          <div
            className={`modal-body ${props.isError && "modal-body-error"} ${
              props.isSuccess && "modal-body-successful"
            }`}
          >
            {props.body}
          </div>
          <div>{props.children}</div>
          {!props.children && (
            <div className="modal-footer">
              <button
                className="modal-close-button"
                onClick={() => {
                  props.onClose();
                  setIsOpen(false);
                }}
              >
                {props.onCloseTitle}
                {props.isOpen}
              </button>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Modal;
